import DOMPurify from 'dompurify';
import {
  ContentChange,
  QuillEditorComponent,
  QuillFormat,
  QuillModules,
  QuillToolbarConfig,
  SelectionChange,
} from 'ngx-quill';
import Quill, { Delta, Range } from 'quill';
import Clipboard from 'quill/modules/clipboard';
import Uploader from 'quill/modules/uploader';
import { debounceTime, Subject } from 'rxjs';
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  effect,
  EventEmitter,
  inject,
  input,
  output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

export const DEFAULT_TOOLBAR_CONFIG: QuillToolbarConfig = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }],
];

const sanitize = (input: string): string => {
  return DOMPurify.sanitize(input, {
    RETURN_TRUSTED_TYPE: false,
    ALLOWED_TAGS: ['b', 'i', 'u', 's', 'strong', 'em', 'ul', 'ol', 'li', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    ALLOWED_ATTR: [],
  }).replaceAll('&nbsp;', ' ');
};

export class NoopUploader extends Uploader {
  override upload(range: Range, files: FileList | File[]): void {
    // do nothing
  }
}

// FIXME: this line denies ALL images from being inserted
// if this becomes a need, find a better solution but make sure it works for text-only editor variants
Uploader.DEFAULTS.mimetypes = [];

export class SanitizedClipboard extends Clipboard {
  override onPaste(range: Range, { html }: { text?: string; html?: string }) {
    const delta = new Delta().retain(range.index).delete(range.length).insert(sanitize(html));

    this.quill.updateContents(delta, Quill.sources.USER);
    this.quill.setSelection(delta.length() - range.length, Quill.sources.SILENT);
    this.quill.scrollSelectionIntoView();
  }

  override onCapturePaste(e: ClipboardEvent): void {
    if (!e.clipboardData.files.length) {
      super.onCapturePaste(e);
    }
  }
}

@Component({
  selector: 'supy-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditorComponent implements ControlValueAccessor {
  readonly #destroyRef = inject(DestroyRef);
  readonly #sanitize = new Subject<void>();
  readonly #cdr = inject(ChangeDetectorRef);
  readonly #control = inject(NgControl, { optional: true });

  @Attribute('name') readonly name: string;
  @ViewChild(QuillEditorComponent, { static: true }) readonly editor: QuillEditorComponent;

  protected readonly formControl = new FormControl('');
  readonly modules = input<QuillModules>({
    toolbar: DEFAULT_TOOLBAR_CONFIG,
    keyboard: {
      bindings: {
        addBinding: {
          key: 'v',
          shortKey: true,
          handler: () => {
            //FIXME: handle sanitize manually because of issue with paste introduced in v2.0.2: https://github.com/slab/quill/issues/4617
            this.#sanitize.next();

            return true;
          },
        },
      },
    },
  });

  readonly value = input<string>();
  readonly format = input<QuillFormat>('html');
  readonly debounce = input(300);

  readonly focus = output<void>();
  readonly blur = output<void>();
  readonly valueChange = output<string>();
  readonly selectionChange = output<[number, number]>();
  readonly submitValue = output<string>();
  readonly cleared = output<MouseEvent>();
  readonly keyDown = output<KeyboardEvent>();

  onChange?: (value?: string) => void;
  onTouched?: (value?: string) => void;

  #touched = false;
  #focused = false;

  protected readonly placeholder = input<string>('');
  protected readonly required = input<boolean>(false);
  protected readonly readOnly = input<boolean>(false);

  protected readonly sanitize = computed(() => this.format() === 'html');

  constructor() {
    if (this.#control) {
      this.#control.valueAccessor = this;
    }

    effect(() => {
      this.formControl.setValue(this.value(), { emitEvent: false });
    });

    this.#sanitize
      .pipe(debounceTime(500), takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.formControl.setValue(sanitize(this.formControl.value), { emitEvent: false }));
  }

  onContentChanged(change: ContentChange) {
    const format = this.format();

    if (format === 'html') {
      const sanitized = sanitize(change.html);

      return this.onChange?.(sanitized);
    }

    if (format === 'text') {
      return this.onChange?.(change.text);
    }
  }

  onSelectionChanged(selection: SelectionChange) {
    const from = selection.range?.index ?? 0;
    const to = selection.range?.length ?? 0;

    this.selectionChange.emit([from, to]);
  }

  onBlur(): void {
    this.#focused = false;
    this.blur.emit();
    this.markAsTouched();
  }

  onFocus(): void {
    this.#focused = true;
    this.focus.emit();
  }

  writeValue(value: string): void {
    const sanitized = sanitize(value);

    this.formControl.setValue(sanitized, { emitEvent: false });
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.#touched) {
      this.#touched = true;
      this.onTouched?.();
      this.#emitTouchStatusChanged();
    }
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }

    this.#cdr.markForCheck();
  }

  #emitTouchStatusChanged(): void {
    if (!this.#control) {
      return;
    }

    const statusChanges = this.#control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
