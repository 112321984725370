import { inject, Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';

import { Logout } from '../actions';

interface AuthStoreState {}

const CURRENT_USER_STATE_TOKEN = new StateToken<AuthStoreState>('auth');

@State<AuthStoreState>({
  name: CURRENT_USER_STATE_TOKEN,
})
@Injectable()
export class AuthenticationState {
  private readonly store = inject(Store);

  @Action(Logout)
  logout(ctx: StateContext<AuthStoreState>) {
    this.store.reset({});
    window.location.reload();
  }
}
