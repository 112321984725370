import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

import { ButtonColor } from '../../../button';
import { IconType } from '../../../icon';

export interface EditableOptionsCellRendererContext {
  readonly buttonColor?: ButtonColor;
  readonly disabled?: boolean;
  readonly iconSuffix: IconType;
  readonly inputSuffix: IconType;
  readonly placeholder?: string;
  readonly validators?: ValidatorFn[];
}

type EditableOptionsCellRendererParams = ICellRendererParams<unknown, string[], EditableOptionsCellRendererContext>;

@Component({
  selector: 'supy-editable-options-cell-renderer',
  template: `
    <div class="supy-editable-options-cell-renderer">
      <supy-editable-options
        #component
        [buttonColor]="context()?.buttonColor ?? 'secondary'"
        [disabled]="context()?.disabled"
        [iconSuffix]="context()?.iconSuffix"
        [inputSuffix]="context()?.inputSuffix"
        iconSize="small"
        [options]="value()"
        [placeholder]="context()?.placeholder"
        [validators]="context()?.validators ?? []"
        (add)="onAdd($event)"
        (remove)="onRemove($event)"
      />
    </div>
  `,
  styleUrls: ['./editable-options-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableOptionsCellRendererComponent implements ICellRendererAngularComp {
  protected readonly value = signal<string[]>([]);
  protected readonly params = signal<EditableOptionsCellRendererParams>(null);
  protected readonly context = signal<EditableOptionsCellRendererContext>(null);

  agInit(params: EditableOptionsCellRendererParams): void {
    this.params.set(params);
    this.context.set(params.context);
    this.value.set(params.value);
  }

  getValue(): string[] {
    return this.value();
  }

  refresh(params: EditableOptionsCellRendererParams): boolean {
    this.params.set(params);
    this.context.set(params.context);
    this.value.set(params.value);

    return true;
  }

  protected onAdd(value: string): void {
    this.value.set([...this.value(), value]);
    this.params().setValue(this.value());
  }

  protected onRemove(value: string): void {
    const data = this.value().slice();
    const targetIndex = this.value().findIndex(val => val === value);

    data.splice(targetIndex, 1);
    this.value.set(data);
    this.params().setValue(this.value());
  }
}
