import { PreferredType } from '../enums';
import { IdType } from '../models';
import { Branch, Retailer } from './branch.entity';
import {
  ChannelMetadata,
  ChannelStatus,
  ChannelSupplier,
  NotificationConfiguration,
  OrderPlacementConfiguration,
  ReceivingConfiguration,
} from './channel.model';
import { OutletData } from './outlet.entity';

export class Channel {
  readonly id: string;
  readonly displayName?: string;
  readonly comments?: string;
  readonly retailer: IdType;
  readonly location: IdType;
  readonly branch: IdType;
  readonly supplier: ChannelSupplier;
  readonly parties: string[];
  readonly state: ChannelStatus;
  readonly orderPlacementConfiguration: OrderPlacementConfiguration;
  readonly notificationConfiguration: NotificationConfiguration;
  readonly receivingConfiguration: ReceivingConfiguration;
  readonly preferredType?: PreferredType;
  readonly metadata?: ChannelMetadata;

  constructor(channel: Channel) {
    this.id = channel.id;
    this.comments = channel.comments;
    this.retailer = channel.retailer;
    this.supplier = channel.supplier;
    this.location = channel.location;
    this.branch = channel.branch;
    this.parties = [channel.location.id, channel.supplier.id];
    this.state = channel.state;
    this.displayName = channel.displayName;
    this.orderPlacementConfiguration = channel.orderPlacementConfiguration;
    this.notificationConfiguration = channel.notificationConfiguration;
    this.receivingConfiguration = channel.receivingConfiguration;
    this.preferredType = channel.preferredType;
  }
}

export class PopulatedChannel extends Channel {
  readonly retailer: Retailer;
  readonly location: Branch;
  readonly branch: OutletData;
}
