import { from, Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OtpGuard {
  private readonly router = inject(Router);

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const state = this.router.getCurrentNavigation()?.extras.state;

    if (state) {
      return true;
    }

    return from(
      this.router.navigate(['..'], {
        replaceUrl: true,
      }),
    );
  }
}
