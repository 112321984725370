<quill-editor
  class="supy-text-editor"
  [formControl]="formControl"
  [required]="required()"
  [placeholder]="placeholder()"
  [readOnly]="formControl.disabled"
  [class.supy-text-editor--disabled]="formControl.disabled"
  [modules]="modules()"
  (onFocus)="onFocus()"
  (onBlur)="onBlur()"
  (onContentChanged)="onContentChanged($event)"
  (onSelectionChanged)="onSelectionChanged($event)"
  [format]="format()"
  [sanitize]="sanitize()"
  [debounceTime]="debounce()"
  [customOptions]=""
/>
<supy-input-hint-container>
  <ng-content select="supy-input-error" />
  <ng-content select="supy-input-hint" />
</supy-input-hint-container>
