import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IDialogCancellableEventArgs, IDialogEventArgs, OverlaySettings } from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';

import { ButtonColor } from '../../../button';
import { IDialogComponent } from '../../interfaces';
import { DialogComponent } from '../dialog';

export interface ConfirmDialogOutput {
  readonly preConfirmCheck: boolean;
}

/**
 * @deprecated Please, use ConfirmDialogWithIconComponent instead
 */
@Component({
  selector: 'supy-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent extends Destroyable implements IDialogComponent {
  @Input() readonly title: string = '';
  @Input() readonly message: string = '';
  @Input() readonly cancelText?: string = $localize`:@@cancel:Cancel`;
  @Input() readonly cancelColor?: ButtonColor = 'default';
  @Input() readonly confirmText?: string = $localize`:@@confirm:Confirm`;
  @Input() readonly confirmColor?: ButtonColor = 'primary';
  @Input() readonly overlaySettings?: OverlaySettings;
  @Input() preConfirmCheck = false;
  @Input() preConfirmCheckText: string;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly confirm = new EventEmitter<ConfirmDialogOutput>();
  @Output() readonly cancel = new EventEmitter<void>();

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  protected readonly sanitizer: DomSanitizer = inject(DomSanitizer);

  get sanitizedMessage(): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.message);
  }

  get sanitizedTitle(): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.title);
  }

  onPreConfirmChange(event: boolean): void {
    this.preConfirmCheck = event;
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onCancelClick(): void {
    this.closeDialog();
    this.cancel.emit();
  }

  onConfirmClick(): void {
    this.closeDialog();
    this.confirm.emit({ preConfirmCheck: this.preConfirmCheck });
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
