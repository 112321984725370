import { debounceTime, filter, map, switchMap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { SideNavItem, SideNavItemType } from '../../core';
import { SideNavService } from '../../services';
import { SideNavSelectGroup } from '../actions';

export interface SideNavStateModel {
  items: SideNavItem[];
  selectedGroup: SideNavItem;
}

export const SIDE_NAV_STATE_TOKEN = new StateToken<SideNavStateModel>('sideNav');

@State<SideNavStateModel>({
  name: SIDE_NAV_STATE_TOKEN,
})
@Injectable()
export class SideNavState implements NgxsOnInit {
  private readonly sideNavService = inject(SideNavService);
  private readonly router = inject(Router);

  @Selector()
  static items(state: SideNavStateModel) {
    return state.items;
  }

  @Selector()
  static topItems(state: SideNavStateModel) {
    return state.items?.filter(item => item.position === 'top');
  }

  @Selector()
  static bottomItems(state: SideNavStateModel) {
    return state.items?.filter(item => item.position === 'bottom');
  }

  @Selector()
  static selectedGroup(state: SideNavStateModel) {
    return state.selectedGroup;
  }

  ngxsOnInit(ctx: StateContext<SideNavStateModel>) {
    const baseUrl = '/';

    this.router.events
      .pipe(
        filter((event): event is RoutesRecognized => event instanceof RoutesRecognized),
        debounceTime(100),
        switchMap(({ url }: RoutesRecognized) =>
          this.sideNavService.items$.pipe(
            map((items: SideNavItem[]) =>
              items.map(item => ({
                ...item,
                active:
                  this.isBaseUrl(baseUrl, url, item.url ?? '') ||
                  this.isSelected(baseUrl, url, item) ||
                  this.anyChildSelected(url, item),
                children: this.setActiveChildren(baseUrl, url, item),
              })),
            ),
          ),
        ),
      )
      .subscribe(items => {
        ctx.patchState({ items });

        const currentSelectedGroup = ctx.getState().selectedGroup;

        if (items.some(item => item.key === currentSelectedGroup?.key)) {
          return;
        }

        ctx.dispatch(new SideNavSelectGroup(null));
      });
  }

  @Action(SideNavSelectGroup)
  selectGroup(ctx: StateContext<SideNavStateModel>, { selectedItem }: SideNavSelectGroup) {
    const items = ctx.getState().items;

    ctx.patchState({
      selectedGroup: items.find(item =>
        selectedItem ? item.key === selectedItem.key : item.type === SideNavItemType.Group && item.active,
      ),
    });
  }

  private isBaseUrl(baseUrl: string, routeUrl: string, itemUrl: string): boolean {
    return routeUrl === baseUrl && routeUrl === itemUrl;
  }

  private isSelected(baseUrl: string, routeUrl: string, item: SideNavItem): boolean {
    if (item.url === baseUrl) {
      return false;
    }

    return (
      (!!item.url && routeUrl.startsWith(item.url)) ||
      (!!item.quickActionUrl && routeUrl.startsWith(item.quickActionUrl))
    );
  }

  private anyChildSelected(url: string, item: SideNavItem): boolean {
    return (item?.children ?? []).some(
      child => (child.url && url.startsWith(child.url)) || this.anyChildSelected(url, child),
    );
  }

  private setActiveChildren(baseUrl: string, url: string, item: SideNavItem): SideNavItem[] {
    return (item?.children ?? []).map(child => ({
      ...child,
      active: url !== baseUrl && child.url ? url.startsWith(child?.url) : false,
      children: this.setActiveChildren(baseUrl, url, child),
    }));
  }
}
