import { Directive, inject, TemplateRef } from '@angular/core';

@Directive({
  selector: '[supyGridDetail]',
})
export class GridDetailTemplateDirective {
  readonly template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}

@Directive({
  selector: '[supyGridRowSelector]',
})
export class GridRowSelectorTemplateDirective {
  readonly template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}

@Directive({
  selector: '[supyGridContextMenu]',
})
export class GridContextMenuTemplateDirective {
  readonly template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}
