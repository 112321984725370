import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IDialogCancellableEventArgs, IDialogEventArgs, OverlaySettings } from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';

import { ButtonColor } from '../../../button';
import { IDialogComponent } from '../../interfaces';
import { DialogComponent, DialogWidth } from '../dialog';

@Component({
  selector: 'supy-dialog-close',
  templateUrl: './dialog-close.component.html',
  styleUrls: ['./dialog-close.component.scss'],
})
export class DialogCloseComponent extends Destroyable implements IDialogComponent {
  @Input() readonly title: string;
  @Input() readonly closeText?: string = $localize`:@@common.actions.close:Close`;
  @Input() readonly closeButtonColor?: ButtonColor = 'default-outline';
  @Input() readonly overlaySettings?: OverlaySettings;
  @Input() readonly width?: DialogWidth = '50vw';

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly closed = new EventEmitter<void>();

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onCloseClick(): void {
    this.closeDialog();
    this.closed.emit();
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
