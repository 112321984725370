import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { QueryParamsHandling } from '@angular/router';

import { IconType } from '../../../icon';
import { TabComponent } from '../tab';

export interface TabItem {
  id?: string;
  path: string | string[];
  label: string;
  icon?: IconType;
  disabled?: boolean;
  badgeText?: string;
  badgeColor?: TabBadgeColor;
  hidden?: boolean;
}

export type TabBadgeColor = 'primary' | 'warn' | 'success' | 'error' | 'info';

@Component({
  selector: 'supy-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  // TODO: check why tab.disabled doesn't work with OnPush
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  private readonly cdr = inject(ChangeDetectorRef);
  @Input() readonly items: TabItem[];
  @Input() readonly headerTemplate: TemplateRef<unknown>;
  @Input() readonly selectedIndex: number;
  @Input() readonly queryParamsHandling?: QueryParamsHandling | null;

  @Output() readonly selectedIndexChange = new EventEmitter<number>();

  @ContentChildren(TabComponent) set children(value: QueryList<TabComponent>) {
    this._children = value;
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 0);
  }

  get children(): QueryList<TabComponent> {
    return this._children;
  }

  _children: QueryList<TabComponent>;

  trackByLabel(_: number, item: TabItem): string {
    return item.label;
  }
}
