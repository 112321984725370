import { Directive, inject, TemplateRef } from '@angular/core';

@Directive({
  selector: '[supyPrefix]',
})
export class PrefixTemplateDirective {
  template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}

@Directive({
  selector: '[supySuffix]',
})
export class SuffixTemplateDirective {
  template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}

@Directive({
  selector: '[supyAddedContent]',
})
export class AddedContentTemplateDirective {
  template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}
