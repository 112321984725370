import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { IconSize, IconType } from '../../../icon';
import { Tag, TagColor, TagFormatMap, TagSize } from '../../models';

@Component({
  selector: 'supy-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  providers: [TitleCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent<T> {
  private readonly titleCasePipe = inject(TitleCasePipe);

  @Input() set tag(value: Tag<T>) {
    if (value) {
      this.#tag = {
        ...value,
        label: TagFormatMap[value.format ?? 'text'](value),
      };

      const type = value.type as string;

      this.type = value.typeShown
        ? value.typeTransform
          ? `${this.titleCasePipe.transform(this.parseType(type))}:`
          : `${type}:`
        : '';
    }
  }

  get tag(): Tag<T> {
    return this.#tag;
  }

  protected type: string;

  @Input() readonly color: TagColor;
  @Input() readonly size: TagSize;
  @Input() readonly removable: boolean;
  @Input() readonly customIcon: IconType;
  @Input() readonly tooltip: string;

  @Output() readonly removed = new EventEmitter<void>();

  #tag: Tag<T> = null;

  readonly iconSizeMap: Record<TagSize, IconSize> = {
    small: 'tiny',
    medium: 'extra-small',
    large: 'small',
  };

  private parseType(type: string): string {
    if (type.includes(' ')) {
      return type; // If input already contains spaces, return unchanged
    }

    return type.replace(/([A-Z])/g, ' $1');
  }
}
