import { interval, Subscription, takeUntil } from 'rxjs';
import { ChangeDetectorRef, Directive, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';

import { Destroyable } from '@supy/common';

@Directive({
  selector: '[supyCountdown]',
  exportAs: 'supyCountdown',
})
export class CountdownDirective extends Destroyable implements OnInit {
  private readonly cdr = inject(ChangeDetectorRef);
  @Input() supyCountdown: number;
  @Input() readonly intervalInMs: number = 1000;
  @Output() readonly countdownFinish = new EventEmitter<void>();

  private readonly minutesInAnHour = 60;
  private readonly secondsInAMinute = 60;

  private _countdown: number;
  private _counter: string; // 'mm:ss'
  private subscription: Subscription;

  get counter(): string {
    return this._counter;
  }

  get countdown(): number {
    return this._countdown;
  }

  ngOnInit(): void {
    this.allocateTimeUnits(this.supyCountdown);
    this.setUpInterval();
  }

  reset(): void {
    this.subscription?.unsubscribe();
    this.setUpInterval();
  }

  private setUpInterval(): void {
    this._countdown = this.supyCountdown;
    this.subscription = interval(this.intervalInMs)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (!this._countdown) {
          this.subscription.unsubscribe();

          return;
        }
        this.allocateTimeUnits(--this._countdown);
      });
  }

  private allocateTimeUnits(time: number) {
    const secondsLeft = Math.floor(time % this.secondsInAMinute);
    const minutesLeft = Math.floor((time / this.minutesInAnHour) % this.secondsInAMinute);

    this._counter = `${minutesLeft}:${secondsLeft.toString().padStart(2, '0')}`;
    this.cdr.detectChanges();
  }
}
