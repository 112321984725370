<div class="supy-card">
  <supy-loading-overlay *ngIf="isLoading" />

  <igx-card>
    <igx-card-header>
      <ng-content select="[header]" />
    </igx-card-header>

    <igx-card-content>
      <ng-content select="[content]" />
    </igx-card-content>

    <igx-card-actions>
      <ng-content select="[actions]" />
    </igx-card-actions>
  </igx-card>
</div>
