import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IgxTimePickerModule } from '@infragistics/igniteui-angular';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { InputHintsModule } from '../input-hints';
import { SelectModule } from '../select';
import { TimePickerComponent, TimePickerDeprecatedComponent } from './components';

@NgModule({
  declarations: [TimePickerDeprecatedComponent, TimePickerComponent],
  imports: [
    CommonModule,
    IconModule,
    ButtonModule,
    InputHintsModule,
    IgxTimePickerModule,
    SelectModule,
    ReactiveFormsModule,
  ],
  exports: [TimePickerDeprecatedComponent, TimePickerComponent, InputHintsModule],
})
export class TimePickerModule {}
