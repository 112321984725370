import { AgGridAngular } from '@ag-grid-community/angular';
import {
  CellClickedEvent,
  CellEditingStoppedEvent,
  CellFocusedEvent,
  type CellPosition,
  ColDef,
  FirstDataRenderedEvent,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  INoRowsOverlayParams,
  IRowDragItem,
  IRowNode,
  IsFullWidthRowParams,
  RowClassParams,
  RowDataUpdatedEvent,
  RowHeightParams,
  SelectionChangedEvent,
  type SelectionOptions,
  SortDirection,
  SuppressKeyboardEventParams,
  type TabToNextCellParams,
} from '@ag-grid-community/core';
import { getLocaleDirection } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  input,
  LOCALE_ID,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';

import { CustomNoRowsOverlayContext, NoRowsOverlayComponent } from './no-row-overlay';

export const GRID_FULL_WIDTH_CELL_CLASSNAME = 'ag-full-width-row';

export type AgGridHeight = `${number}rem` | `${number}%` | 'auto';

export interface GridSortOrder {
  readonly colId: string;
  readonly direction: SortDirection;
}

@Component({
  selector: 'supy-grid-poc',
  templateUrl: './grid-poc.component.html',
  styleUrls: ['./grid-poc.component.scss'],
})
export class GridPocComponent<T = unknown> implements OnChanges {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly locale = inject<string>(LOCALE_ID);

  @Input()
  set columnDefs(columnDefs: ColDef[]) {
    this.#columnDefs =
      columnDefs?.map(colDef => ({ ...colDef, suppressMovable: colDef.suppressMovable ?? true })) ?? [];
  }

  get columnDefs(): ColDef[] {
    return this.#columnDefs;
  }

  @Input()
  readonly data: T[] = [];

  @Input()
  pinnedBottomRowData: T[] = [];

  @Input()
  readonly fullWidthCellRendererParams: unknown;

  @Input()
  /** Type of Row Selection: `single`, `multiple`.
   * @deprecated Instead, set `selection.mode` to `'singleRow'` or `'multiRow'`
   */
  readonly rowSelection: AgGridAngular['rowSelection'];

  @Input()
  readonly domLayout: AgGridAngular['domLayout'] = 'autoHeight';

  @Input()
  readonly height: AgGridHeight = 'auto';

  @Input()
  readonly disableSelectOnClick: boolean;

  @Input()
  readonly components: AgGridAngular['components'];

  @Input()
  readonly editType: AgGridAngular['editType'];

  @Input()
  readonly isAddedInFooterDisabled: boolean;

  @Input()
  readonly tabToNextCell: ((params: TabToNextCellParams<T>) => CellPosition | boolean | null) | undefined;

  @Input()
  set defaultColDefs(value: ColDef) {
    if (value) {
      this.defaultColDef = {
        ...this.defaultColDef,
        ...value,
      };
    }
  }

  get defaultColDefs(): ColDef {
    return this.defaultColDef;
  }

  @Input() protected readonly tooltipShowDelay: number = 0;
  @Input() protected readonly tooltipHideDelay: number = 5000;

  @Input() readonly noRowsOverlayComponent = NoRowsOverlayComponent;
  @Input() readonly noRowsOverlayComponentContext: CustomNoRowsOverlayContext;

  @Input() readonly rowDragManaged: boolean;
  @Input() readonly rowDragMultiRow: boolean;
  @Input() readonly suppressMoveWhenRowDragging: boolean;
  @Input() readonly rawDragText: (params: IRowDragItem, count: number) => string = () => '';
  readonly selection = input<SelectionOptions | undefined>();
  protected readonly rowMultiSelectWithClick = input<boolean>();

  @Output()
  readonly gridReady: EventEmitter<GridReadyEvent> = new EventEmitter<GridReadyEvent>();

  @Output()
  readonly cellClick: EventEmitter<CellClickedEvent<T>> = new EventEmitter<CellClickedEvent<T>>();

  @Output()
  readonly cellFocus: EventEmitter<CellFocusedEvent> = new EventEmitter<CellFocusedEvent>();

  @Output()
  readonly sortChanged: EventEmitter<GridSortOrder[]> = new EventEmitter<GridSortOrder[]>();

  @Output()
  readonly cellEditingStopped: EventEmitter<CellEditingStoppedEvent<T>> = new EventEmitter<
    CellEditingStoppedEvent<T>
  >();

  @Output()
  readonly rowSelectionChange: EventEmitter<SelectionChangedEvent<T>> = new EventEmitter<SelectionChangedEvent<T>>();

  @Output()
  readonly pageChange: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  readonly addRow: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  protected readonly rowDataUpdated: EventEmitter<RowDataUpdatedEvent<T>> = new EventEmitter<RowDataUpdatedEvent<T>>();

  @Output()
  readonly firstDataRendered = new EventEmitter<FirstDataRenderedEvent<T>>();

  #columnDefs: ColDef[] = [];

  // DefaultColDef sets props common to all Columns
  private defaultColDef: ColDef = {
    sortable: false,
    filter: false,
    singleClickEdit: true,
    suppressKeyboardEvent({ event }: SuppressKeyboardEventParams) {
      const { key, shiftKey } = event;
      const path = getEventPath(event);
      const isTabForward = key === 'Tab' && !shiftKey;
      const isTabBackward = key === 'Tab' && shiftKey;
      let suppressEvent = false;

      // Handle cell children tabbing
      if (isTabForward || isTabBackward) {
        const eGridCell = path.find(el => {
          if (el.classList === undefined) {
            return false;
          }

          return el.classList.contains(GRID_FULL_WIDTH_CELL_CLASSNAME) || el.classList.contains('ag-cell');
        });

        if (!eGridCell) {
          return suppressEvent;
        }

        const focusableChildrenElements = getAllFocusableElementsOf(eGridCell);
        const lastCellChildEl = focusableChildrenElements[focusableChildrenElements.length - 1];
        const firstCellChildEl = focusableChildrenElements[0];

        // Suppress keyboard event if tabbing forward within the cell and the current focused element is not the last child
        if (isTabForward && focusableChildrenElements.length > 0) {
          const isLastChildFocused = lastCellChildEl && document.activeElement === lastCellChildEl;

          if (!isLastChildFocused) {
            suppressEvent = true;
          }
        }
        // Suppress keyboard event if tabbing backwards within the cell, and the current focused element is not the first child
        else if (isTabBackward && focusableChildrenElements.length > 0) {
          const cellHasFocusedChildren =
            eGridCell.contains(document.activeElement) && eGridCell !== document.activeElement;

          // Manually set focus to the last child element if cell doesn't have focused children
          if (!cellHasFocusedChildren) {
            lastCellChildEl.focus();
            // Cancel keyboard press, so that it doesn't focus on the last child and then pass through the keyboard press to
            // move to the 2nd last child element
            event.preventDefault();
          }

          const isFirstChildFocused = firstCellChildEl && document.activeElement === firstCellChildEl;

          if (!isFirstChildFocused) {
            suppressEvent = true;
          }
        }
      }

      return suppressEvent;
    },
  };

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular<T>;

  @Input()
  isFullWidthRow: (params: IsFullWidthRowParams) => boolean;

  @Input()
  getRowHeight: (params: RowHeightParams) => number | undefined | null;

  @Input()
  getRowId: (params: GetRowIdParams) => string | undefined | null;

  @Input()
  readonly getRowClass: (params: RowClassParams) => string | string[] | undefined | null;

  @Input()
  fullWidthCellRenderer: AgGridAngular['fullWidthCellRenderer'];

  @Input()
  protected readonly isPaginated: boolean;

  @Input()
  protected readonly pageIndex: number;

  @Input()
  protected readonly perPage: number;

  @Input()
  protected readonly nextPageDisabled: boolean;

  @Input()
  protected readonly prevPageDisabled: boolean;

  @Input()
  protected readonly addedInFooter: boolean;

  @Input()
  protected readonly addedInFooterLoading: boolean;

  @Input()
  protected readonly addedInFooterTitle: string = $localize`:@@addInFooterTitle: Click to add items`;

  @Input()
  readonly gridOptions: AgGridAngular['gridOptions'] = {
    enableCellTextSelection: true,
    ensureDomOrder: true,
    alwaysMultiSort: true,
  };

  get api(): GridApi<T> {
    // TODO: hide api and expose only required methods;
    return this.agGrid?.api;
  }

  get noRowsOverlayParams(): Partial<INoRowsOverlayParams> {
    return {
      context: this.noRowsOverlayComponentContext,
    };
  }

  protected readonly dir: 'ltr' | 'rtl';

  constructor() {
    this.dir = getLocaleDirection(this.locale);
  }

  ngOnChanges(): void {
    this.pinnedBottomRowData = [...this.pinnedBottomRowData];
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridReady.emit(params);
  }

  onCellClicked(e: CellClickedEvent<T>): void {
    this.cellClick.emit(e);
  }

  onCellFocused(e: CellFocusedEvent): void {
    this.cellFocus.emit(e);
  }

  onSortChanged(): void {
    const colState = this.agGrid?.api?.getColumnState() ?? [];
    const ordering: GridSortOrder[] = colState.reduce((acc, curr) => {
      if (curr.sort) {
        acc.push({
          colId: curr.colId,
          direction: curr.sort,
        });
      }

      return acc;
    }, [] as GridSortOrder[]);

    this.sortChanged.emit(ordering);
  }

  onRowSelectionChanged(e: SelectionChangedEvent<T>): void {
    this.rowSelectionChange.emit(e);
  }

  protected onRowDataUpdated(e: RowDataUpdatedEvent<T>): void {
    this.rowDataUpdated.emit(e);
  }

  markForCheck(): void {
    this.cdr.markForCheck();
  }

  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }

  selectRows(rows: T[]): void {
    const nodesToSelect: IRowNode[] = [];
    const rowsSet = new Set(rows);

    this.agGrid.api.forEachNode(node => {
      if (node.data && rowsSet.has(node.data)) {
        nodesToSelect.push(node);
      }
    });

    this.agGrid.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  }

  deselectRows(rows: T[]): void {
    const nodesToSelect: IRowNode[] = [];
    const rowsSet = new Set(rows);

    this.agGrid.api.forEachNode(node => {
      if (node.data && rowsSet.has(node.data)) {
        nodesToSelect.push(node);
      }
    });
    this.agGrid.api.setNodesSelected({ nodes: nodesToSelect, newValue: false });
  }

  onAddRow(): void {
    this.addRow.emit();
  }

  onFirstDataRendered(e: FirstDataRenderedEvent<T>): void {
    this.firstDataRendered.emit(e);
  }

  onPageChange(page: number): void {
    this.pageChange.emit(page);
  }

  setColumnDefs(colDefs: ColDef[]) {
    const columnDefs: ColDef[] =
      colDefs?.map(colDef => ({ ...colDef, suppressMovable: colDef.suppressMovable ?? true })) ?? [];

    this.api?.setGridOption('columnDefs', columnDefs);
  }

  setRowData<N extends T>(data: N[]) {
    this.api?.setGridOption('rowData', data);
  }

  setQuickFilter(searchText: string) {
    this.api?.setGridOption('quickFilterText', searchText);

    const noRowsDislayed = !this.api.getDisplayedRowCount();

    noRowsDislayed ? this.api.showNoRowsOverlay() : this.api.hideOverlay();
  }
}

function getEventPath(event: Event): HTMLElement[] {
  const path: HTMLElement[] = [];
  let currentTarget: HTMLElement = event.target as HTMLElement;

  while (currentTarget) {
    path.push(currentTarget);
    currentTarget = currentTarget.parentElement;
  }

  return path;
}

function getAllFocusableElementsOf(el: HTMLElement) {
  return Array.from<HTMLElement>(
    el.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'),
  ).filter(focusableEl => {
    return focusableEl.tabIndex !== -1;
  });
}
