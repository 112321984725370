import { Currency } from '@supy.api/dictionaries';

import { InvoiceUnitTypeEnum } from '../enums';
import { IdType, LocalizedData, SkeletonObjectType } from '../models';
import { Supplier } from './supplier.entity';
import { Uom } from './uom.entity';

export enum SupplierItemState {
  available = 'available',
  deleted = 'deleted',
}

export enum SupplierItemCreationSource {
  Admin = 'admin',
  Supplier = 'supplier',
  Generated = 'generated',
}

export class SupplierItemMetadata {
  readonly description?: string;
  readonly supplierItemCode?: string;
  readonly currency: Currency;
  readonly isInStock: boolean;
}

export class SupplierItem {
  readonly id: string;

  readonly name: LocalizedData;
  readonly 'name.en'?: string;
  readonly 'name.ar'?: string;

  readonly uomId: string;
  readonly uom: Uom;

  readonly origin: SkeletonObjectType;
  readonly brand: SkeletonObjectType;

  readonly supplierId: string;
  readonly supplier: Supplier;
  readonly 'supplier.name'?: string;

  readonly metadata: SupplierItemMetadata;
  readonly 'metadata.supplierItemCode'?: string;
  readonly 'metadata.isInStock'?: boolean;
  readonly creationSource?: SupplierItemCreationSource;

  readonly state: SupplierItemState;

  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface BaseInvoiceUnit {
  readonly name: string;
  readonly type: InvoiceUnitTypeEnum;
  readonly conversionToAtom: number;
}

export interface UomInvoiceUnit extends BaseInvoiceUnit {
  readonly type: InvoiceUnitTypeEnum.Uom;
  readonly uom: IdType;
}

export interface PackagingInvoiceUnit extends BaseInvoiceUnit {
  readonly type: InvoiceUnitTypeEnum.Package;
  readonly packaging: IdType;
}

export type InvoiceUnit = PackagingInvoiceUnit | UomInvoiceUnit;
