<supy-tabs class="supy-tabview-select" [selectedIndex]="selectedIndex">
  <supy-tab *ngFor="let item of items">
    <supy-tab-header *ngIf="!item.hidden">
      <div [id]="item.id" class="supy-tabview-select__item" (click)="onClick(item)">
        <supy-icon *ngIf="item.icon" [name]="item.icon" size="small" />
        <span class="supy-tabview-select__label"> {{ item.label }}</span>
        <span *ngIf="item.suffix" class="supy-tabview-select__item-suffix"> {{ item.suffix }}</span>
        <div class="supy-tabview-select__suffix">
          <ng-content select="[tabSuffix]" />
        </div>
      </div>
    </supy-tab-header>
  </supy-tab>
</supy-tabs>
