<div class="supy-snackbar__line" [ngClass]="['supy-snackbar--' + snackBarData.variant + '-line']"></div>
<span class="supy-snackbar__content" [ngClass]="['supy-snackbar--' + snackBarData.variant]" matSnackBarLabel>
  @if (snackBarData.icon) {
    <supy-button class="supy-snackbar__icon-button" type="icon" [round]="true" width="full" height="full">
      <supy-icon [name]="snackBarData.icon" class="supy-snackbar__icon" [color]="snackBarData.variant ?? 'primary'" />
    </supy-button>
  }
  @if (snackBarData.message) {
    <p class="supy-snackbar__title" [innerHTML]="sanitizedMessage"></p>
  }
</span>
<span matSnackBarActions class="supy-snackbar__action">
  @if (snackBarData.actionText) {
    <supy-button matSnackBarAction (click)="snackBarRef.dismissWithAction()">
      {{ snackBarData.actionText }}
    </supy-button>
  } @else {
    <supy-icon
      matSnackBarAction
      (click)="snackBarRef.dismissWithAction()"
      name="close"
      size="small"
      color="gray"
      class="supy-snackbar__action"
    />
  }
</span>
