import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { UnleashService } from '../unleash.service';
import { UnleashContextExtractFrom, UnleashContextScope, UnleashResolverRouteData } from '../unleash-route';
import { BaseUnleashResolver } from './base-unleash.resolver';

/**
 * @description
 * Used as a default resolver that reads `route.queryParams` or `route.params` based on
 * the provided `extractContextFrom` with a key based on the provided `scope` "defaults to `id`".
 *
 * @usage
 * ```Typescript
 * const routes: UnleashRoute[] = [
 *  {
 *    path: ':id/suppliers',
 *    resolve: {unleashRetailer: DefaultUnleashResolver}
 *  }
 * ];
 *
 * const routes: UnleashRoute[] = [
 *  {
 *    path: ':branchId/suppliers',
 *    resolve: {unleashRetailer: DefaultUnleashResolver},
 *    data: {
 *      unleashContext: {scope: UnleashContextScope.Branch}
 *    }
 *  }
 * ];
 * ```
 */
@Injectable()
export class DefaultUnleashResolver extends BaseUnleashResolver {
  protected readonly unleashService = inject(UnleashService);

  resolve(route: ActivatedRouteSnapshot): Promise<void> {
    const { unleashContext } = route.data as UnleashResolverRouteData;
    const key = unleashContext?.scope ?? UnleashContextScope.Id;
    const object =
      unleashContext?.extractContextFrom === UnleashContextExtractFrom.Query ? route.queryParams : route.params;

    if (!object[key]) {
      throw new Error(`The provided ${key} cannot be found in ${JSON.stringify(object)}`);
    }

    return this.updateContextAndResolve({ userId: object[key] as string });
  }
}
