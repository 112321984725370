<igx-date-range-picker
  class="supy-date-range {{ variant }} {{ density }} supy-input--compact"
  [class.selected]="!!datePicker.value"
  type="border"
  #datePicker
  (closing)="onClosingPicker($event)"
  [value]="value"
  (valueChange)="onValueChange($event)"
  [inputFormat]="inputFormat"
  [displayFormat]="displayFormat"
  [placeholder]="placeholder"
  [overlaySettings]="overlaySettings"
  [attr.data-name]="name"
  [hideOutsideDays]="hideOutsideDays"
  [disabled]="disabled"
  [displayMonthsCount]="displayMonthsCount"
>
  <ng-template igxPickerActions>
    <div class="supy-date-range__actions" *ngIf="predefinedRanges?.length">
      <ng-container *ngFor="let predefinedRange of predefinedRanges">
        <supy-button color="default" (buttonClick)="onSelectRange(predefinedRange)">
          <ng-container [ngSwitch]="predefinedRange.by" *ngIf="!predefinedRange.customLabel; else customLabel">
            <ng-container *ngSwitchCase="PredefinedRangeBy.Days" i18n="@@dateRange.lastDays"
              >Last {{ predefinedRange.range }} days</ng-container
            >
            <ng-container *ngSwitchCase="PredefinedRangeBy.Months">
              @if (predefinedRange.range > 1) {
                <ng-container i18n="@@dateRange.lastMonths">Last {{ predefinedRange.range }} Months</ng-container>
              } @else {
                <ng-container i18n="@@dateRange.lastMonth">Last Month</ng-container>
              }
            </ng-container>
            <ng-container *ngSwitchCase="PredefinedRangeBy.Weeks">
              @if (predefinedRange.range > 1) {
                <ng-container i18n="@@dateRange.lastWeeks">Last {{ predefinedRange.range }} Weeks</ng-container>
              } @else {
                <ng-container i18n="@@dateRange.lastWeek">Last Week</ng-container>
              }
            </ng-container>
            <ng-container *ngSwitchCase="PredefinedRangeBy.Quarter">
              @if (predefinedRange.range > 1) {
                <ng-container i18n="@@dateRange.lastQuarters">Last {{ predefinedRange.range }} Quarter</ng-container>
              } @else {
                <ng-container i18n="@@dateRange.lastQuarter">Last Quarter</ng-container>
              }
            </ng-container>
            <ng-container *ngSwitchCase="PredefinedRangeBy.Years">
              @if (predefinedRange.range > 1) {
                <ng-container i18n="@@dateRange.lastYears">Last {{ predefinedRange.range }} Years</ng-container>
              } @else {
                <ng-container i18n="@@dateRange.lastYear">Last Year</ng-container>
              }
            </ng-container>
          </ng-container>

          <ng-template #customLabel>{{ predefinedRange.customLabel }}</ng-template>
        </supy-button>
      </ng-container>
      <supy-button
        (buttonClick)="onDone()"
        class="supy-date-range__actions-done"
        color="primary"
        i18n="@@common.actions.done"
        >Done</supy-button
      >
    </div>
  </ng-template>

  <label igxLabel>{{ label }}</label>
  <igx-picker-toggle igxSuffix>
    <supy-icon name="arrow-circle-down" size="small" />
  </igx-picker-toggle>
</igx-date-range-picker>
