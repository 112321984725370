import { AnimationEvent } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  input,
  output,
} from '@angular/core';

import { popoverAnimation } from './popover-animation';

export const POPOVER_MAX_WIDTH = 20;

@Component({
  selector: 'supy-popover-container',
  templateUrl: './popover-container.component.html',
  styleUrl: './popover-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [popoverAnimation],
})
export class PopoverContainerComponent implements AfterViewInit {
  readonly #cdr = inject(ChangeDetectorRef);
  readonly elementRef = inject(ElementRef);

  @HostBinding('@animation') animationState = 'hidden';

  @HostListener('@animation.done', ['$event']) done(event: AnimationEvent) {
    if (event.toState === 'hidden') {
      this.closed.emit();
    }
  }

  protected readonly maxWidth = input<number>(POPOVER_MAX_WIDTH);

  readonly closed = output<void>();

  ngAfterViewInit(): void {
    this.animationState = 'visible';
  }

  close(): void {
    this.animationState = 'hidden';
    this.#cdr.markForCheck();
  }
}
