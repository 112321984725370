import { Directive, inject, TemplateRef } from '@angular/core';

@Directive({
  selector: '[supySelectFooter]',
})
export class SelectFooterTemplateDirective {
  template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}

@Directive({
  selector: '[supySelectHeader]',
})
export class SelectHeaderTemplateDirective {
  template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}
