<div class="supy-date-input supy-input--compact" #date>
  <igx-date-picker
    type="border"
    class="ng-touched {{ density }}"
    (closed)="onClosePicker()"
    [overlaySettings]="overlaySettings"
    [value]="value"
    (valueChange)="onValueChange($event)"
    [inputFormat]="inputFormat"
    [displayFormat]="displayFormat"
    [placeholder]="placeholder"
    [minValue]="minValue"
    [maxValue]="maxValue"
    [disabled]="disabled"
    [attr.data-name]="name"
    [class.supy-date-input-warn]="suffixColor === 'warn'"
  >
    <ng-template igxPickerActions>
      <div class="supy-date-input__actions" *ngIf="predefinedDates?.length">
        <ng-container *ngFor="let predefinedDate of predefinedDates">
          <supy-button color="default" (buttonClick)="onSelectPredefinedDate(predefinedDate)" i18n="@@dateInput.daysAgo"
            >{{ predefinedDate?.customLabel ? predefinedDate.customLabel : predefinedDate.daysAgo }} Days ago
          </supy-button>
        </ng-container>
        <supy-button
          color="primary"
          class="supy-date-input__actions-done"
          (buttonClick)="datePicker.close()"
          i18n="@@common.actions.done"
          >Done</supy-button
        >
      </div>
    </ng-template>

    <igx-picker-toggle igxSuffix>
      <supy-icon *ngIf="!disabled" name="arrow-circle-down" size="small" [color]="suffixColor" />
    </igx-picker-toggle>

    <igx-hint>
      <supy-input-hint-container class="supy-date-input__hint-container">
        <ng-content select="supy-input-error" />
        <ng-content select="supy-input-hint" />
      </supy-input-hint-container>
    </igx-hint>
  </igx-date-picker>
</div>
