import { PreferredType } from '../enums';
import { IdType } from '../models';
import { Branch, Retailer } from './branch.entity';
import { OutletData } from './outlet.entity';
import { PaymentMethod, SupplierType } from './supplier.entity';

export enum ChannelStatus {
  active = 'active',
  deleted = 'deleted',
}

export enum ChannelType {
  category = 'category',
  anonymous = 'anonymous',
  centralKitchen = 'central-kitchen',
}

export enum ExternalChannelType {
  Whatsapp = 'whatsapp',
  Email = 'email',
}

export enum DayOfWeek {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export enum CreditPeriodType {
  Net15 = 'net-15',
  Net30 = 'net-30',
  Net60 = 'net-60',
  MonthEnd = 'month-end',
  MonthEndPlus30 = 'month-end-plus-30',
  MonthEndPlus60 = 'month-end-plus-60',
  InvoiceDate = 'invoice-date',
  Custom = 'custom',
}

export interface ExternalChannel {
  type: ExternalChannelType;
  value: string;
}

export interface CutOffSchedule {
  readonly deliveryDay: DayOfWeek;
  readonly isActive: boolean;
  readonly cutOffDay: DayOfWeek;
  readonly cutOffTime: number;
}

export interface ChannelSupplier {
  readonly id: string;
  readonly type: SupplierType;
  readonly isExposed: boolean;
  readonly logoUrl?: string;
}

export interface OrderPlacementConfiguration {
  readonly minimumOrderLimit?: number;
  readonly cutOffSchedule?: CutOffSchedule[];
}

export interface NotificationConfiguration {
  readonly externals?: ExternalChannel[];
  readonly hideLpoPrices?: boolean;
  readonly hideLpoUnits?: boolean;
  readonly sendEmail?: boolean;
  readonly sendWhatsapp?: boolean;
  readonly additionalInstructions?: string;
}

export interface ChannelCreditPeriod {
  readonly type: CreditPeriodType;
  readonly value?: number;
}

export interface ReceivingConfiguration {
  readonly defaultTaxId?: string;
  readonly defaultHandlingFeePercentage?: number;
  readonly paymentMethod?: PaymentMethod;
  readonly creditPeriod?: ChannelCreditPeriod;
}

export interface ChannelMetadata {
  readonly externalRetailerId?: string;
  readonly externalRetailerIdLabel?: string;
  readonly trn?: string;
}

export interface ChannelResponse {
  readonly id: string;
  readonly displayName?: string;
  readonly comments?: string;
  readonly retailer: IdType | Retailer;
  readonly location: IdType | Branch;
  readonly branch: IdType | OutletData;
  readonly supplier: ChannelSupplier;
  readonly parties: string[];
  readonly state: ChannelStatus;
  readonly orderPlacementConfiguration: OrderPlacementConfiguration;
  readonly notificationConfiguration: NotificationConfiguration;
  readonly receivingConfiguration: ReceivingConfiguration;
  readonly preferredType?: PreferredType;
  readonly metadata?: ChannelMetadata;
}

export interface ChannelQueryProps {
  readonly 'location.id': string;
  readonly 'retailer.id': string;
  readonly retailerId: string;
  readonly 'supplier.id': string;
  readonly state: ChannelStatus;
}
