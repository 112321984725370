<div *ngIf="tag" class="supy-tag supy-tag__{{ tag.size ?? size ?? 'medium' }} supy-tag__{{ tag.color ?? color }}">
  <div class="supy-tag__content">
    <span class="supy-tag__text">{{ type }} {{ tag.label }}</span>

    <supy-icon
      class="supy-tag__remove"
      *ngIf="tag.removable ?? removable ?? true"
      (click)="removed.emit()"
      name="close"
      [size]="iconSizeMap[tag?.size ?? size ?? 'medium']"
    />

    <supy-icon
      class="supy-tag__remove"
      *ngIf="customIcon"
      [name]="customIcon"
      [size]="iconSizeMap[tag?.size ?? size ?? 'medium']"
    />

    <supy-icon *ngIf="tooltip" name="info" size="small" position="top" [supyTooltipDeprecated]="tooltipTemplate" />
  </div>
</div>

<supy-tooltip-deprecated #tooltipTemplate> {{ tooltip }}</supy-tooltip-deprecated>
