export enum AccountingEventType {
  Grn = 'grn',
  SupplierReturn = 'supplier-return',
}

export enum InventoryEventType {
  Wastage = 'wastage',
  Production = 'production',
  Transfer = 'transfer',
  StockCount = 'stock-count',
  Recipe = 'recipe',
  Order = 'order',
}

export enum IntegrationEventType {
  Sales = 'sales',
}
