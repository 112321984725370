import { Directive, inject, TemplateRef } from '@angular/core';

@Directive({
  selector: '[supyComboEmpty]',
})
export class ComboEmptyDirective {
  template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}

@Directive({
  selector: '[supyComboFooter]',
})
export class ComboFooterDirective {
  template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}

@Directive({
  selector: '[supyComboItem]',
})
export class ComboItemDirective {
  template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}
