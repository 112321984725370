<supy-dialog
  (dialogClosed)="onDialogClosed($event)"
  (dialogClosing)="onDialogClosing($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  position="center"
  width="85vw"
  height="90vh"
  initialState="open"
>
  <supy-dialog-header class="expanded-bubble-chart__dialog-header">
    <div class="expanded-bubble-chart__header">
      <div>
        <h5 class="expanded-bubble-chart__title">{{ customTitle }}</h5>
        <p class="expanded-bubble-chart__subtitle" *ngIf="currency && !subTitle">in {{ currency }}</p>
        <p class="expanded-bubble-chart__subtitle" *ngIf="subTitle">{{ subTitle }} in {{ currency }}</p>
      </div>
      <div class="expanded-bubble-chart__header-icons">
        <supy-icon *ngIf="canExport" (click)="exportClicked.emit()" name="document-download" color="primary" />
        <supy-icon (click)="closeDialog()" name="close-circle" color="error" />
      </div>
    </div>
  </supy-dialog-header>
  <supy-dialog-content class="expanded-bubble-chart__content">
    <supy-bubble-chart
      [data]="data"
      [colorsMap]="colorsMap"
      [svgWidth]="svgWidth"
      [svgHeight]="svgHeight"
      [categoryFn]="categoryFn"
    >
      <ng-template supyChartTooltip let-datum>
        <ng-container *ngIf="tooltipTemplate">
          <ng-container *ngTemplateOutlet="tooltipTemplate; context: { $implicit: datum }" />
        </ng-container>
      </ng-template>
    </supy-bubble-chart>
    <div class="expanded-bubble-chart__legend">
      <div class="expanded-bubble-chart__legend-item" *ngFor="let legendItem of legendItems">
        <span class="expanded-bubble-chart__legend-symbol" [style.background-color]="legendItem.color"></span
        >{{ legendItem.label }}
      </div>
    </div>
  </supy-dialog-content>
</supy-dialog>
