<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger()"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy()"
  [cdkConnectedOverlayOpen]="isOpened()"
  (overlayOutsideClick)="popoverContainerRef().close()"
  (detach)="detach()"
  cdkConnectedOverlayTransformOriginOn="supy-popover-container"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayPositions]="popoverPositions()"
  [cdkConnectedOverlayHasBackdrop]="hasBackdrop()"
  [cdkConnectedOverlayBackdropClass]="backdropClass()"
>
  <supy-popover-container #popoverContainer (closed)="onClosePopover()" [maxWidth]="maxWidth()">
    <ng-content />
  </supy-popover-container>
</ng-template>
