import { filter, Observable, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { IgxIconLoadedEvent } from '@infragistics/igniteui-angular';

import { IconRegistryService } from './icon-registry.service';

@Injectable()
export class IconService {
  private readonly iconRegistryService = inject(IconRegistryService);
  static readonly FAMILY: string = IconRegistryService.FAMILY;
  private readonly iconsLoading = new Map<string, Observable<IgxIconLoadedEvent>>();

  isCached(name: string): boolean {
    return this.iconRegistryService.isCached(name, IconService.FAMILY);
  }

  getAvailableIconList(): string[] {
    return this.iconRegistryService.getAvailableIconList();
  }

  getIcon(name: string, url?: string): Observable<IgxIconLoadedEvent> {
    const isLoading = this.iconsLoading.has(name);

    if (isLoading) {
      return this.iconsLoading.get(name);
    }

    if (url) {
      this.iconRegistryService.addSvgIcon(name, url, IconService.FAMILY);
    }

    const loader = this.iconRegistryService.iconLoaded.pipe(
      filter(e => e.family === IconService.FAMILY),
      tap(() => this.iconsLoading.delete(name)),
    );

    this.iconsLoading.set(name, loader);

    return loader;
  }
}
