import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';
import { Currency } from '@supy.api/dictionaries';

import { Destroyable } from '@supy/common';

import { DialogComponent, IDialogComponent } from '../../../../../dialog';
import { TreemapColor, TreemapInput } from '../../treemap.interfaces';
import { TreemapTilingMethod } from '../treemap';

@Component({
  selector: 'supy-expanded-treemap',
  templateUrl: './expanded-treemap.component.html',
  styleUrls: ['./expanded-treemap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandedTreemapComponent<T extends TreemapInput = TreemapInput>
  extends Destroyable
  implements IDialogComponent
{
  @Input() readonly customTitle: string;
  @Input() readonly subTitle?: string;
  @Input() readonly isLoading?: boolean;
  @Input() readonly currency: Currency;
  @Input() readonly data: T;

  @Input() readonly isClickable?: boolean;
  @Input() readonly tooltipTemplate?: TemplateRef<{ $implicit: unknown }>;
  @Input() readonly colors: TreemapColor[];
  @Input() readonly tilingMethod: keyof TreemapTilingMethod = 'binary';
  @Input() readonly sectionsMap: Map<string, string>;
  @Input() readonly headerTemplate: TemplateRef<unknown>;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  protected svgWidth = window.innerWidth * 0.8;
  protected svgHeight = window.innerHeight * 0.75;

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
