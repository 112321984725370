import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, inject } from '@angular/core';

import { ButtonColor } from '../../../button';
import { IconColor, IconSize, IconType } from '../../../icon';

export interface ActionsCellRendererContext<TData = unknown, TValue = unknown> {
  readonly actions: ActionCellRendererAction<TData, TValue>[];
}

export interface ActionCellRendererAction<TData, TValue> {
  readonly type?: TValue;
  readonly icon?: IconType;
  readonly iconSize?: IconSize;
  readonly iconColor?: IconColor;
  readonly buttonColor?: ButtonColor;
  readonly title?: string;
  readonly tooltip?: string;
  readonly moreActions?: ActionCellRendererAction<TData, TValue>[];
  readonly disabled?: boolean;
  readonly hidden?: boolean;
  readonly callback?: (data: TData, event?: PointerEvent) => void;
}

export enum ActionCellRendererActionTypes {
  More = 'more',
  Expand = 'expand',
}

@Component({
  selector: 'supy-actions-cell-renderer',
  template: `
    <div class="supy-actions-cell-renderer__cell">
      <ng-container *ngFor="let action of params.context?.actions">
        <ng-container *ngIf="!action?.hidden">
          <supy-button
            *ngIf="action?.type !== actionTypes.More; else moreActions"
            [color]="action?.buttonColor ?? 'default'"
            [type]="!action?.title ? 'icon' : 'button'"
            [disabled]="action?.disabled"
            (buttonClick)="action?.callback ? action?.callback(params.data, $event) : null"
            cdkOverlayOrigin
            #tooltip="cdkOverlayOrigin"
          >
            <ng-container *ngIf="action.tooltip; else noTooltipIcon">
              <supy-icon
                [class.loading]="action?.icon === 'loading'"
                [name]="action?.icon"
                [size]="action?.iconSize ?? 'small'"
                [color]="action?.disabled ? 'gray' : action?.iconColor"
              />

              <supy-popover [trigger]="tooltip" [positions]="['start-center']" [triggerEvent]="['click', 'hover']">{{
                action?.tooltip
              }}</supy-popover>
            </ng-container>
            <ng-template #noTooltipIcon>
              <supy-icon
                [class.loading]="action?.icon === 'loading'"
                [name]="action?.icon"
                [size]="action?.iconSize ?? 'small'"
                [color]="action?.iconColor"
              />
            </ng-template>
            <span *ngIf="action?.title">{{ action?.title }}</span>
          </supy-button>

          <ng-template #moreActions>
            <supy-dropdown-toggle
              [dropdownRef]="supyDropdown"
              [elementRef]="documentElementRef"
              position="bottom-left"
              class="supy-actions-cell-renderer__dropdown-toggle"
            >
              <supy-button [color]="action?.buttonColor ?? 'default'" type="icon" [disabled]="action?.disabled">
                <supy-icon
                  [class.loading]="action?.icon === 'loading'"
                  [name]="action?.icon ?? 'dots'"
                  [size]="action?.iconSize ?? 'small'"
                  [color]="action?.iconColor"
                />
              </supy-button>
            </supy-dropdown-toggle>
            <supy-dropdown
              #supyDropdown
              action
              name="more-actions"
              [customClasses]="['supy-actions-cell-renderer__dropdown']"
            >
              <supy-dropdown-item
                *ngFor="let subAction of action?.moreActions"
                (click)="subAction?.callback(params?.data)"
              >
                <supy-icon
                  [class.loading]="action?.icon === 'loading'"
                  [name]="subAction?.icon"
                  [size]="action?.iconSize ?? 'small'"
                  [color]="subAction?.iconColor"
                />
                <span>{{ subAction?.title }}</span>
              </supy-dropdown-item>
            </supy-dropdown>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  `,
  styleUrls: ['./actions-cell-renderer.component.scss'],
})
export class ActionsCellRendererComponent<
  TData = unknown,
  TValue extends ActionCellRendererActionTypes = ActionCellRendererActionTypes,
  TContext extends ActionsCellRendererContext<TData, TValue> = ActionsCellRendererContext<TData, TValue>,
> implements ICellRendererAngularComp
{
  protected params: ICellRendererParams<TData, TValue, TContext>;
  protected readonly actionTypes = ActionCellRendererActionTypes;
  protected document = inject(DOCUMENT);

  get documentElementRef(): ElementRef<HTMLElement> {
    return {
      nativeElement: this.document.body,
    };
  }

  agInit(params: ICellRendererParams<TData, TValue, TContext>): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
