import { StringValue } from 'ms';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  Inject,
  inject,
  Input,
  input,
  model,
  OnInit,
  Optional,
  Output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

import { generateTimeOptions, SupyTimepickerOption } from './util';

const DEFAULT_PICKER_INTERVAL: StringValue = '30m';

@Component({
  selector: 'supy-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  private readonly cdr = inject(ChangeDetectorRef);

  readonly #destroyRef = inject(DestroyRef);
  readonly min? = input<number>();
  readonly max? = input<number>();
  @Input() @HostBinding('attr.name') readonly name: string;
  value = model<number>();

  @Input() set disabled(disabled: boolean) {
    this.#disabled = disabled;
    this.setDisabledState(disabled);
  }

  get disabled(): boolean {
    return this.#disabled;
  }

  readonly interval = input<StringValue>(DEFAULT_PICKER_INTERVAL);
  readonly options = input<SupyTimepickerOption[] | null>(null);

  @Output() readonly valueChanged = new EventEmitter<number>();

  #disabled = false;

  protected readonly timeOptions = signal<SupyTimepickerOption[]>([]);

  private onChange: (value: number) => void;
  private onTouched: () => void;
  private touched = false;
  private readonly selectControl = new FormControl<number>({ value: null, disabled: false });

  get formControl(): FormControl<number> {
    return (this.control.control as FormControl) ?? this.selectControl;
  }

  constructor(@Optional() @Inject(NgControl) private readonly control?: NgControl) {
    if (control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.control) {
      this.control.valueAccessor = this;
      this.formControl.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(value => {
        this.value.set(value);
      });

      this.value.set(this.formControl.value);
    }

    this.generateOptions();
    this.cdr.detectChanges();
  }

  onValueChange(value: number): void {
    if (this.disabled) {
      return;
    }

    if (!value) {
      this.value.set(null);
    } else {
      this.value.set(value);
    }
    this.formControl.setValue(value ?? null);
    this.valueChanged.emit(value ?? null);
    this.onChange?.(this.value());
    this.cdr.markForCheck();
  }

  onFocusOut(): void {
    this.markAsTouched();
  }

  writeValue(value: number): void {
    this.value.set(value);
  }

  registerOnChange(onChange: (value: number) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.#disabled = disabled;
    disabled ? this.formControl.disable() : this.formControl.enable();
  }

  private markAsTouched(): void {
    if (this.touched) {
      return;
    }

    this.touched = true;
    this.formControl.markAsTouched();
    this.onTouched?.();
    this.emitTouchStatusChanged();
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }

  private generateOptions(): void {
    const interval = this.interval() ?? DEFAULT_PICKER_INTERVAL;
    const options = this.options();

    if (options !== null) {
      this.timeOptions.set(options);
    } else {
      const min = this.min() ?? 0;
      const max = this.max() ?? 24 * 3600000 - 1;

      const timeOptions = generateTimeOptions(min, max, interval);

      timeOptions.push({
        value: 86340000,
        label: '11:59pm',
      });

      this.timeOptions.set(timeOptions);
    }
  }
}
