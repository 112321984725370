import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { UnleashService } from '../unleash.service';
import { UnleashContextExtractFrom, UnleashContextScope } from '../unleash-route';
import { BaseUnleashGuard } from './base-unleash.guard';

/**
 * @description
 * Used as a default guard that validates the provided `toggles` against `route.queryParams` or `route.params`
 * based on the provided `extractContextFrom` with a key based on the provided `scope` "defaults to `id`"
 *
 * @usage
 * ```Typescript
 * const routes: UnleashRoute[] = [
 *  {
 *    path: ':id/suppliers',
 *    canActivate: [DefaultUnleashGuard],
 *    data: {
 *      unleashContext: {toggles: [ToggleFeature.SomeFeature]}
 *    }
 *  }
 * ];
 *
 * const routes: UnleashRoute[] = [
 *  {
 *    path: ':branchId/suppliers',
 *    canActivate: [DefaultUnleashGuard],
 *    data: {
 *      unleashContext: {toggles: [ToggleFeature.SomeFeature], scope: UnleashContextScope.Branch}
 *    }
 *  }
 * ];
 * ```
 */
@Injectable()
export class DefaultUnleashGuard extends BaseUnleashGuard {
  protected readonly unleashService = inject(UnleashService);

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const unleashContext = this.getContext(route);

    const key = unleashContext.scope || UnleashContextScope.Id;
    const object =
      unleashContext.extractContextFrom === UnleashContextExtractFrom.Query ? route.queryParams : route.params;

    if (!object[key]) {
      throw new Error(`The provided ${key} cannot be found in ${JSON.stringify(object)}`);
    }

    await this.updateContext({ userId: object[key] as string });

    return await this.activate(unleashContext.toggles);
  }
}
