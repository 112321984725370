<div class="secondary-layout">
  <div class="secondary-layout__container">
    <ng-content select="[header]" />
  </div>

  <main>
    <div class="secondary-layout__container">
      <ng-content select="[content]" />
    </div>
  </main>
</div>
