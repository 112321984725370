import { NonFunctionProperties } from '@supy/core';

import { IdType, LocalizedData } from '../models';
import { ViewBranch } from './branch.entity';
import { BranchRegion } from './branch-region.entity';

export class OutletData {
  constructor(args: NonFunctionProperties<OutletData>) {
    Object.assign(this, args);
  }

  readonly id: string;
  readonly name: LocalizedData;
  readonly phone: string;
  readonly address: OutletAddressDto;
  readonly workTime: OutletWorkTimeDto;
  readonly retailer: IdType;
  readonly email?: string;
  readonly logoUrl?: string;
  readonly color?: string;
  readonly state: OutletStateEnum;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly branches: ViewBranch[] & { color: string }[];
  readonly region?: BranchRegion;
  readonly taxRegistrationNumber?: string;
}

export class OutletDataWithNameEN extends OutletData {
  readonly nameEn?: string;
}

export class OutletAddressDto {
  readonly city: string;
  readonly country: IdType;
  readonly address: string;
}

export class OutletWorkTimeDto {
  readonly opensAt?: number;
  readonly closesAt?: number;
  readonly cutoff: number;
}

export enum OutletStateEnum {
  Active = 'active',
  Inactive = 'inactive',
}
