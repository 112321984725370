import ms, { StringValue } from 'ms';

export interface SupyTimepickerOption {
  value: number;
  label: string;
}

export function parseInterval(intervalStr: StringValue): number {
  return ms(intervalStr);
}

function formatTime(ms, format) {
  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  let formatted = '';

  if (format === '24h') {
    formatted = String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');

    if (seconds !== 0) {
      formatted += ':' + String(seconds).padStart(2, '0');
    }
  } else if (format === '12h') {
    const period = hours >= 12 ? 'pm' : 'am';
    let hour12 = hours % 12;

    if (hour12 === 0) hour12 = 12;
    formatted = hour12 + ':' + String(minutes).padStart(2, '0');

    if (seconds !== 0) {
      formatted += ':' + String(seconds).padStart(2, '0');
    }
    formatted += period;
  } else {
    throw new Error("Unsupported format. Use '12h' or '24h'.");
  }

  return formatted;
}

export function generateTimeOptions(
  min: number,
  max: number,
  intervalStr: StringValue,
  format = '12h',
): SupyTimepickerOption[] {
  const intervalMs = parseInterval(intervalStr);
  const options: SupyTimepickerOption[] = [];

  for (let ms = min; ms <= max; ms += intervalMs) {
    options.push({
      value: ms,
      label: formatTime(ms, format),
    });
  }

  return options;
}
