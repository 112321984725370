<div class="supy-grid-poc">
  <ag-grid-angular
    class="ag-theme-alpine"
    [ngStyle]="{ height: domLayout === 'normal' ? height : '' }"
    [columnDefs]="columnDefs"
    [tooltipShowDelay]="tooltipShowDelay"
    [tooltipHideDelay]="tooltipHideDelay"
    [tabToNextCell]="tabToNextCell"
    [enableBrowserTooltips]="true"
    [defaultColDef]="defaultColDefs"
    [rowData]="data"
    [pinnedBottomRowData]="pinnedBottomRowData"
    [isFullWidthRow]="isFullWidthRow"
    [fullWidthCellRenderer]="fullWidthCellRenderer"
    [fullWidthCellRendererParams]="fullWidthCellRendererParams"
    [animateRows]="true"
    [getRowClass]="getRowClass"
    [stopEditingWhenCellsLoseFocus]="true"
    [suppressFocusAfterRefresh]="true"
    [headerHeight]="33"
    [getRowHeight]="getRowHeight"
    [domLayout]="domLayout"
    [rowMultiSelectWithClick]="rowMultiSelectWithClick()"
    [suppressRowClickSelection]="disableSelectOnClick"
    [rowSelection]="rowSelection"
    [selection]="selection()"
    [components]="components"
    [ensureDomOrder]="true"
    [gridOptions]="gridOptions"
    (firstDataRendered)="onFirstDataRendered($event)"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayParams"
    [editType]="editType"
    [enableRtl]="dir === 'rtl'"
    [stopEditingWhenCellsLoseFocus]="false"
    [getRowId]="getRowId"
    [rowDragMultiRow]="rowDragManaged"
    [rowDragManaged]="rowDragManaged"
    [suppressMoveWhenRowDragging]="suppressMoveWhenRowDragging"
    [rowDragText]="rawDragText"
    (gridReady)="onGridReady($event)"
    (cellClicked)="onCellClicked($event)"
    (cellFocused)="onCellFocused($event)"
    (sortChanged)="onSortChanged()"
    (selectionChanged)="onRowSelectionChanged($event)"
    (rowDataUpdated)="onRowDataUpdated($event)"
    (cellEditingStopped)="cellEditingStopped.emit($event)"
  />

  <ng-container *ngIf="addedInFooter">
    <supy-button color="default-outline" width="full" (buttonClick)="onAddRow()" [disabled]="isAddedInFooterDisabled">
      <span class="supy-grid-poc-footer__add-row-content">
        <span class="supy-grid-poc-footer__add-row-content-label">{{ addedInFooterTitle }}</span>
        <supy-icon name="plus-flat-circle" title="Add" color="primary" size="medium" [loading]="addedInFooterLoading" />
      </span>
    </supy-button>
  </ng-container>

  <supy-pagination
    *ngIf="isPaginated"
    [previousDisabled]="prevPageDisabled"
    [nextDisabled]="nextPageDisabled"
    [pageIndex]="pageIndex"
    (indexChange)="onPageChange($event)"
  />
</div>
