import { CutOffSchedule, DayOfWeek } from '../entities';

export const DAY_OF_WEEK_INDEX_MAP: Record<DayOfWeek, number> = {
  [DayOfWeek.Sunday]: 0,
  [DayOfWeek.Monday]: 1,
  [DayOfWeek.Tuesday]: 2,
  [DayOfWeek.Wednesday]: 3,
  [DayOfWeek.Thursday]: 4,
  [DayOfWeek.Friday]: 5,
  [DayOfWeek.Saturday]: 6,
};

function getCutOffDateTime(
  targetDeliveryDate: Date,
  { cutOffDay, cutOffTime }: { cutOffDay: DayOfWeek | null; cutOffTime: number | null },
): Date | null {
  if (!cutOffDay || cutOffTime == null) {
    return null;
  }

  const deliveryDow = targetDeliveryDate.getDay();
  const cutoffDow = DAY_OF_WEEK_INDEX_MAP[cutOffDay];
  let dayDiff = cutoffDow - deliveryDow;

  if (dayDiff > 0) {
    dayDiff -= 7;
  }

  const cutoffDate = new Date(targetDeliveryDate);

  cutoffDate.setDate(cutoffDate.getDate() + dayDiff);
  cutoffDate.setHours(0, 0, 0, 0);
  cutoffDate.setTime(cutoffDate.getTime() + cutOffTime);

  return cutoffDate;
}

export function isBeforeCutOff(now: Date, targetDate: Date, entry: CutOffSchedule): boolean {
  const cutoff = getCutOffDateTime(targetDate, entry);

  return cutoff ? now < cutoff : false;
}

export function hasCutOffPassedForDelivery(
  selectedDeliveryDate: Date,
  schedules: CutOffSchedule[],
  now: Date = new Date(),
): boolean {
  const deliveryDate = new Date(selectedDeliveryDate);

  deliveryDate.setHours(0, 0, 0, 0);

  const jsDay = deliveryDate.getDay();
  const scheduleIndex = jsDay === 0 ? 6 : jsDay - 1;
  const schedule = schedules[scheduleIndex];

  if (!schedule || !schedule.isActive) {
    // If there's no active schedule entry for this day, consider it unavailable.
    return false;
  }

  const cutoffDate = getCutOffDateTime(deliveryDate, {
    cutOffDay: schedule.cutOffDay,
    cutOffTime: schedule.cutOffTime,
  });

  if (!cutoffDate) {
    return false;
  }

  return now.getTime() > cutoffDate.getTime();
}

export function getNextDeliveryDates(schedule: CutOffSchedule[], now: Date, count: number): Date[] {
  const active = schedule.filter(s => s.isActive);

  if (active.length === 0 || active.length === 7) {
    return [];
  }

  const results: Date[] = [];
  const dayCursor = new Date(now);

  while (results.length < count) {
    for (const entry of active) {
      if (dayCursor.getDay() === DAY_OF_WEEK_INDEX_MAP[entry.deliveryDay]) {
        if (isBeforeCutOff(now, dayCursor, entry)) {
          results.push(new Date(dayCursor));
          break;
        }
      }
    }

    dayCursor.setDate(dayCursor.getDate() + 1);
  }

  return results;
}
