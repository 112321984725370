import { formatDate } from '@angular/common';

export type TagSize = 'small' | 'medium' | 'large';
export type TagColor = 'primary' | 'secondary' | 'gray' | 'error' | 'success' | 'warn' | 'info' | 'light-info';
export type TagFormat = 'text' | 'date';

export const TagFormatMap = {
  text: ({ label }: Tag<unknown>): string => label,
  date: ({ label }: Tag<unknown>): string => formatDate(label, 'dd/MM/yyyy', 'en-GB'),
};

export interface Tag<T> {
  readonly label: string;
  readonly id?: string;
  readonly color?: TagColor;
  readonly size?: TagSize;
  readonly removable?: boolean;
  readonly typeShown?: boolean;
  readonly typeTransform?: boolean;
  readonly type?: T;
  readonly format?: TagFormat;
}
