import { debounceTime, Subject, takeUntil } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  input,
  OnInit,
  Output,
} from '@angular/core';

import { Destroyable } from '@supy/common';

import { IconColor } from '../../../icon';

@Component({
  selector: 'supy-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent extends Destroyable implements OnInit {
  readonly clearable = input<boolean>();
  readonly isLoading = input<boolean>();

  @Input() @HostBinding('attr.name') readonly name: string = 'search';
  @Input() readonly placeholder?: string = 'Search';
  @Input() readonly latency: number = 0;
  @Input() value: string;
  @Input() readonly suffixColor: IconColor = 'gray';

  @Output() readonly valueChange = new EventEmitter<string>();

  private readonly debouncer$ = new Subject<string>();

  ngOnInit(): void {
    this.debouncer$.pipe(debounceTime(this.latency), takeUntil(this.destroyed$)).subscribe(v => {
      this.valueChange.emit(v);
    });
  }

  onValueChange(value: string): void {
    this.debouncer$.next(value);
  }
}
