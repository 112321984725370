import { Component, Input } from '@angular/core';
import { TooltipPosition, TooltipTouchGestures } from '@angular/material/tooltip';

@Component({
  selector: 'supy-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() content = '';
  @Input() position: TooltipPosition = 'above';
  @Input() showDelay = 0;
  @Input() hideDelay = 500;
  @Input() touchGestures: TooltipTouchGestures = 'auto';
}
