import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  AutoPositionStrategy,
  DateRangeDescriptor,
  IgxDatePickerComponent,
  OverlaySettings,
} from '@infragistics/igniteui-angular';
import { swingInTopFwd } from '@infragistics/igniteui-angular/animations';
import { IANATimezone } from '@supy.api/dictionaries';

import { compareLocalDatePart, getDateInCurrentTime, getDateInTimeZone } from '@supy/common';

import { IconColor } from '../../../icon';
import { InputDensity } from '../../../input';

export interface PredefinedDate {
  readonly daysAgo: number;
  readonly customLabel?: string;
}

export function getDefaultPredefinedDates(): PredefinedDate[] {
  return [
    { daysAgo: 0, customLabel: $localize`:@@today:Today` },
    { daysAgo: 1, customLabel: $localize`:@@yesterday:Yesterday` },
    { daysAgo: 7 },
    { daysAgo: 30 },
  ];
}

@Component({
  selector: 'supy-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// FIXME: When used as a formControl it's not initialized with provided through formGroup value
export class DateInputComponent implements AfterViewInit, ControlValueAccessor {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly control = inject(NgControl, { optional: true });
  @ViewChild('date') readonly outlet: ElementRef<HTMLDivElement>;
  @ViewChild(IgxDatePickerComponent) readonly datePicker: IgxDatePickerComponent;

  @Output() readonly valueChange = new EventEmitter<Date>();
  @Output() readonly closed = new EventEmitter<Date>();
  @Input() value = new Date();
  @Input() @HostBinding('attr.name') readonly name: string;
  @Input() readonly inputFormat: string = 'dd.MM.yyyy';
  @Input() readonly displayFormat: string = 'dd.MM.yyyy';
  @Input() readonly placeholder: string = $localize`:@@common.date:Date`;
  @Input() readonly minValue: Date = null;
  @Input() readonly maxValue: Date = null;
  @Input() readonly ianaTimeZone?: IANATimezone;
  @Input() readonly density: InputDensity = 'small';

  @Input() readonly suffixColor: IconColor;

  @Input() protected readonly predefinedDates: PredefinedDate[] = [];
  @Input() disabled: boolean;

  _disabledDates: DateRangeDescriptor[];

  onChange: (value: Date) => void;

  onTouched: () => void;

  touched = false;

  overlaySettings: OverlaySettings;
  skipInitialEmit = true;

  constructor() {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.overlaySettings = {
      outlet: this.outlet,
      positionStrategy: new AutoPositionStrategy({
        openAnimation: swingInTopFwd,
      }),
    };
  }

  onSelectPredefinedDate(predefinedDate: PredefinedDate): void {
    const today = new Date();
    const date = new Date(new Date().setDate(today.getDate() - predefinedDate.daysAgo));
    const tzDate = this.ianaTimeZone ? getDateInTimeZone(date, this.ianaTimeZone) : date;

    // prevent choosing dates before minValue or after maxValue by comparing the date parts only
    if (
      (this.minValue && compareLocalDatePart(tzDate, this.minValue) < 0) ||
      (this.maxValue && compareLocalDatePart(tzDate, this.maxValue) > 0)
    ) {
      this.datePicker?.writeValue(null);

      return;
    }

    this.writeValue(tzDate);

    this.datePicker.close();
  }

  @HostListener('click')
  openDatePicker(): void {
    this.datePicker.open();
  }

  onValueChange(event: Date): void {
    let tzDate: Date = null;

    if (event) {
      const newEvent = getDateInCurrentTime(event);

      tzDate = this.ianaTimeZone ? getDateInTimeZone(newEvent, this.ianaTimeZone) : newEvent;

      /**
       * Compare the date parts of the timezoned selected, minimum, and maximum values. If out-of-bounds, write null
       */

      const tzMinValue =
        this.minValue &&
        new Date(
          this.ianaTimeZone
            ? getDateInTimeZone(getDateInCurrentTime(this.minValue), this.ianaTimeZone).toISOString()
            : this.minValue,
        );
      const tzMaxValue =
        this.maxValue &&
        new Date(
          this.ianaTimeZone
            ? getDateInTimeZone(getDateInCurrentTime(this.maxValue), this.ianaTimeZone).toISOString()
            : this.maxValue,
        );

      if (
        (this.minValue && compareLocalDatePart(tzDate, tzMinValue) < 0) ||
        (this.maxValue && compareLocalDatePart(tzDate, tzMaxValue) > 0)
      ) {
        this.datePicker?.writeValue(null);

        return;
      }
    }

    if ((this.control && !this.skipInitialEmit) || !this.control) {
      this.valueChange.emit(tzDate);
    }

    this.skipInitialEmit = false;
    this.onChange?.(tzDate);
  }

  onClosePicker(): void {
    this.closed.emit(this.value);
    this.markAsTouched();
  }

  writeValue(value: Date): void {
    this.onValueChange(value);
    this.value = value;

    if (!value) {
      this.datePicker?.writeValue(value);
    }

    setTimeout(() => {
      this.cdr.markForCheck();
    }, 0);
  }

  registerOnChange(onChange: (value: Date) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
