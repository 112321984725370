import { isEqual } from 'lodash-es';

/**
 * Returns the difference between two objects.
 *
 * @param originalObj - The original object.
 * @param updatedObj - The updated object.
 * @param keysToCheck - specify keys to look into.
 * @param alwaysVerifyEquality - decide if method should always compare both objects properties
 * @returns The difference between the two objects.
 */
export function getObjectDiff<T extends object>({
  originalObj,
  updatedObj,
  keysToCheck,
  alwaysVerifyEquality,
}: GetObjDiffArgs<T>): Partial<T> {
  const keys =
    keysToCheck ?? (Array.from(new Set([...Object.keys(originalObj), ...Object.keys(updatedObj)])) as (keyof T)[]);

  return keys.reduce<Partial<T>>((result, key) => {
    if (!(key in updatedObj) && !alwaysVerifyEquality) {
      result[key] = undefined;
    } else if (!isEqual(originalObj[key], updatedObj[key])) {
      result[key] = updatedObj[key];
    }

    return result;
  }, {});
}

/**
 * Returns the updated keys between two objects.
 *
 * @param originalObj - The original object.
 * @param updatedObj - The updated object.
 * @param keysToCheck - specify keys to look into.
 * @returns The updated keys between the two objects.
 */

export function getObjectDiffKeys<T extends object>({
  originalObj,
  updatedObj,
  keysToCheck,
}: GetObjDiffArgs<T>): (keyof T)[] {
  const keys =
    keysToCheck ?? (Array.from(new Set([...Object.keys(originalObj), ...Object.keys(updatedObj)])) as (keyof T)[]);

  return keys.reduce<(keyof T)[]>((result, key) => {
    if (!(key in updatedObj)) {
      result.push(key);
    } else if (!isEqual(originalObj[key], updatedObj[key])) {
      result.push(key);
    }

    return result;
  }, []);
}

interface GetObjDiffArgs<T> {
  readonly originalObj: T;
  readonly updatedObj: T;
  readonly keysToCheck?: Array<keyof T>;
  readonly alwaysVerifyEquality?: boolean;
}
