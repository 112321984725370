import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PopoverComponent, PopoverContainerComponent } from './components';

@NgModule({
  declarations: [PopoverComponent, PopoverContainerComponent],
  imports: [CommonModule, OverlayModule],
  exports: [PopoverComponent, OverlayModule, ScrollingModule],
})
export class PopoverModule {}
