import { QUILL_CONFIG_TOKEN, QuillConfig, QuillEditorComponent } from 'ngx-quill';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IconModule } from '../icon';
import { InputHintsModule } from '../input-hints';
import { LoaderModule } from '../loader';
import { NoopUploader, SanitizedClipboard, TextEditorComponent } from './components';

@NgModule({
  declarations: [TextEditorComponent],
  providers: [
    {
      provide: QUILL_CONFIG_TOKEN,
      useValue: {
        suppressGlobalRegisterWarning: true,
        customModules: [
          {
            path: 'modules/clipboard',
            implementation: SanitizedClipboard,
          },
          {
            path: 'modules/uploader',
            implementation: NoopUploader,
          },
        ],
      } as QuillConfig,
    },
  ],
  imports: [CommonModule, InputHintsModule, IconModule, LoaderModule, QuillEditorComponent, ReactiveFormsModule],
  exports: [TextEditorComponent],
})
export class TextEditorModule {}
