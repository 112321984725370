<div class="supy-input-container supy-input--compact">
  <igx-input-group
    [class.supy-input-line]="inputType === 'line'"
    [class.supy-input-success]="suffixColor === 'success'"
    [class.supy-input-warn]="suffixColor === 'warn'"
    [class.supy-input-addon]="addonText?.length"
    class="supy-input {{ density }}"
    theme="material"
    [type]="inputType"
  >
    <ng-container *ngIf="prefixTemplate?.template" [ngTemplateOutlet]="prefixTemplate.template" igxPrefix />
    <ng-container *ngIf="suffixTemplate?.template" [ngTemplateOutlet]="suffixTemplate.template" igxSuffix />

    <span igxPrefix *ngIf="shownPrefix">
      <supy-icon [name]="shownPrefix" [size]="density" />
    </span>

    <span igxSuffix *ngIf="shownSuffix && !isLoading && (!clearable || !value)">
      <supy-icon [name]="shownSuffix" [size]="density" [color]="suffixColor" />
    </span>

    <input
      igxInput
      [igxTextSelection]="textSelection"
      [supyNumeric]="numeric"
      [precision]="precision"
      [id]="id"
      [name]="name"
      [type]="fixedType()"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [autocomplete]="autocomplete"
      [value]="value"
      [required]="required"
      (input)="onValueChange($event)"
      (focusout)="onFocusOut($event)"
      (focusin)="onFocusIn($event)"
      (keypress)="onInputKeypress($event)"
      (keydown)="handleKeydown($event)"
      [min]="min ?? ''"
      [max]="max ?? ''"
      [step]="step ?? ''"
      [readOnly]="readonly"
      [class]="{
        'supy-input__input--link': type() === 'link',
        'supy-input__input--text-center': centerText,
        'supy-input__input--no-shadow': noShadow,
        'supy-input__input--readonly': readonly,
        'supy-input__input--invalid': true,
      }"
    />
    <span igxSuffix *ngIf="clearable && value && !isLoading">
      <supy-icon (click)="clearInput()" name="close-circle" color="error" [size]="density" />
    </span>

    <span igxSuffix *ngIf="isLoading">
      <supy-loader color="primary" />
    </span>

    <igx-hint class="supy-input__hint-placeholder">
      <supy-input-hint-container>
        <ng-content select="supy-input-error" />
        <ng-content select="supy-input-hint" />
      </supy-input-hint-container>
    </igx-hint>
  </igx-input-group>

  <div
    *ngIf="addonText"
    class="supy-input__addon"
    [ngClass]="{
      'supy-input__addon--success': suffixColor === 'success',
      'supy-input__addon--warn': suffixColor === 'warn',
      'supy-input__addon--disabled': disabled,
    }"
  >
    {{ addonText }}
  </div>

  <ng-container *ngIf="addedContentTemplate?.template" [ngTemplateOutlet]="addedContentTemplate.template" />
</div>
