<supy-loading-bar />
<aside class="supy-aside">
  <supy-side-nav />
</aside>
<div class="supy-main-wrapper">
  <main id="main" class="supy-main" cdkScrollable>
    <header class="supy-header">
      <ng-content select="[header]" />
    </header>
    <ng-content select="[content]" />
  </main>
</div>
