import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { AuthService } from '../../services';
import { Logout } from '../../store';

@Component({
  selector: 'supy-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly navigation: Navigation | null;

  constructor() {
    this.navigation = this.router.getCurrentNavigation();
  }

  async ngOnInit(): Promise<void> {
    const { state } = this.navigation?.extras ?? {};

    this.authService.logOut();
    this.store.dispatch(new Logout());

    await this.router.navigateByUrl('/auth/login', {
      replaceUrl: true,
      state: { ...state },
    });
  }
}
