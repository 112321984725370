import { Directive, inject, TemplateRef } from '@angular/core';

@Directive({
  selector: '[supyFilterCellTemplate]',
})
export class FilterCellTemplateDirective {
  template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}

@Directive({
  selector: '[supyGridCell]',
})
export class GridCellTemplateDirective {
  template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}

@Directive({
  selector: '[supyGridCellEditor]',
})
export class GridCellEditorTemplateDirective {
  template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}

@Directive({
  selector: '[supyGridCellHeader]',
})
export class GridCellHeaderTemplateDirective {
  template = inject<TemplateRef<unknown>>(TemplateRef<unknown>);
}
