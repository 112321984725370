<div class="supy-confirm-dialog-icon">
  <supy-dialog
    position="center"
    initialState="open"
    [width]="width"
    [closeOnEscape]="closeOnEscape"
    [closeOnOutsideClick]="closeOnOutsideClick"
    (dialogClosed)="onDialogClosed($event)"
    (dialogClosing)="onDialogClosing($event)"
    (dialogOpened)="onDialogOpened($event)"
    (dialogOpening)="onDialogOpening($event)"
  >
    <supy-dialog-header>
      <div class="supy-confirm-dialog-icon__header-wrapper">
        <supy-icon *ngIf="icon" class="supy-confirm-dialog-icon__header-icon" [name]="icon" [color]="iconColor" />
        <ng-container *ngIf="title">
          <h3 class="supy-confirm-dialog-icon__content" [innerHTML]="sanitizedTitle"></h3>
        </ng-container>

        @if (showCloseIcon) {
          <supy-icon
            (click)="dialog.closeDialog()"
            class="supy-confirm-dialog-icon__header-wrapper-close"
            name="close-circle"
            size="medium"
          />
        }
      </div>
    </supy-dialog-header>
    <supy-dialog-content>
      <ng-container *ngIf="message">
        <p [innerHTML]="sanitizedMessage"></p>
      </ng-container>
      <ng-container *ngIf="additionalContent">
        <ng-container *ngTemplateOutlet="additionalContent" />
      </ng-container>
    </supy-dialog-content>
    <supy-dialog-footer class="supy-confirm-dialog-icon__footer">
      <div class="supy-confirm-dialog-icon__footer-confirm">
        <supy-checkbox
          *ngIf="preConfirmCheckText?.length"
          [checked]="preConfirmCheck"
          (checkedChange)="onPreConfirmChange($event)"
        >
          {{ preConfirmCheckText }}</supy-checkbox
        >
      </div>
      <div class="supy-confirm-dialog-icon__footer-buttons">
        <supy-button *ngIf="cancelText" (buttonClick)="onCancelClick()" [color]="cancelColor" name="cancel">
          {{ cancelText }}
        </supy-button>
        <supy-button *ngIf="confirmText" (buttonClick)="onConfirmClick()" [color]="confirmColor" name="confirm">
          {{ confirmText }}
        </supy-button>
      </div>
    </supy-dialog-footer>
  </supy-dialog>
</div>
