<div class="supy-confirm-dialog">
  <supy-dialog
    position="center"
    initialState="open"
    (dialogClosed)="onDialogClosed($event)"
    (dialogClosing)="onDialogClosing($event)"
    (dialogOpened)="onDialogOpened($event)"
    (dialogOpening)="onDialogOpening($event)"
  >
    <supy-dialog-header backgroundImage="assets/images/supy-dialog-header-2.svg" />
    <supy-dialog-content>
      <ng-container *ngIf="title">
        <h3 class="supy-confirm-dialog__content">{{ title }}</h3>
      </ng-container>
      <ng-container *ngIf="message">
        <p>{{ message }}</p>
      </ng-container>
    </supy-dialog-content>
    <supy-dialog-footer>
      <supy-button (buttonClick)="onCancelClick()" [color]="cancelColor" name="cancel">{{ cancelText }}</supy-button>
      <supy-button (buttonClick)="onConfirmClick()" [color]="confirmColor" name="confirm">{{
        confirmText
      }}</supy-button>
    </supy-dialog-footer>
  </supy-dialog>
</div>
