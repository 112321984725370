<div class="supy-tags">
  <ng-container *ngFor="let tag of tags; trackBy: tackByIndex; let index = index">
    <supy-tag
      *ngIf="!limit || index <= limit"
      [tag]="tag"
      [color]="color"
      [size]="size"
      [removable]="removable"
      (removed)="tagRemoved.emit(tag)"
    />
  </ng-container>

  <supy-dropdown-toggle [dropdownRef]="supyDropdown" action>
    <supy-tag
      *ngIf="tags?.length - 1 > limit"
      [tag]="moreTags"
      [color]="color"
      [size]="size"
      [removable]="false"
      [customIcon]="customIcon"
    />
  </supy-dropdown-toggle>

  <supy-dropdown #supyDropdown>
    <ng-container *ngFor="let tag of tags; trackBy: tackByIndex; let index = index">
      <supy-dropdown-item *ngIf="index > limit" [disabled]="true">
        <supy-tag [tag]="tag" [color]="color" [size]="size" [removable]="removable" (removed)="tagRemoved.emit(tag)" />
      </supy-dropdown-item>
    </ng-container>
  </supy-dropdown>
</div>
