<mat-menu class="supy-dropdown" [attr.data-name]="name" [style.width]="width">
  <div class="items-list-actions--initial-loading"></div>

  <div class="supy-dropdown__list" [ngClass]="getCustomClasses()">
    <button
      *ngFor="let item of children"
      mat-menu-item
      class="supy-dropdown__item"
      [class.supy-dropdown__item--disabled]="item.disabled"
      [routerLink]="item.routerLink"
      [ngClass]="item.class"
      [disabled]="item.disabled"
      (click)="!item.disabled && item.click.emit($event)"
    >
      <ng-template [ngTemplateOutlet]="item.template" />
    </button>
  </div>
</mat-menu>
