import { catchError, concatMap, filter, from, Observable, Subject, switchMap, take, throwError } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { APP_CONFIG } from '@supy/core';

import { AuthConfig } from '../../config';
import { AuthUser } from '../../core';
import { AuthService } from '../../services';

@Injectable()
export class AuthenticationInterceptor<T, R> implements HttpInterceptor {
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  private readonly config = inject<AuthConfig>(APP_CONFIG);
  private readonly tokenRefreshedSource = new Subject<string>();
  private readonly tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  private isRefreshing = false;

  intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<R>> {
    const { apiUrl, apiUrlBff, apiUrlPrefix } = this.config;

    if (
      (request.url.startsWith(`${apiUrl}${apiUrlPrefix}`) ||
        request.url.startsWith(`${apiUrlBff}${apiUrlPrefix}`) ||
        request.url.includes('/ws/')) &&
      !request.url.startsWith(`${apiUrl}${apiUrlPrefix}/authentication`) &&
      !request.url.startsWith(`${apiUrlBff}${apiUrlPrefix}/authentication`)
    ) {
      return this.authService.user$.pipe(
        take(1),
        switchMap(user => {
          let authRequest = request;

          if (this.isRefreshing) {
            return this.tokenRefreshed$.pipe(
              filter(Boolean),
              take(1),
              concatMap(token => next.handle(this.addTokenHeader(request, token))),
            );
          }

          if (user) {
            const { accessToken } = (this.authService.authUser as AuthUser) ?? {};

            authRequest = this.addTokenHeader(request, accessToken);
          }

          return next.handle(authRequest).pipe(
            catchError((error: HttpErrorResponse) => {
              if (
                error instanceof HttpErrorResponse &&
                (error.status as HttpStatusCode) === HttpStatusCode.Unauthorized
              ) {
                const authUser = this.authService.authUser;

                return this.handleUnauthorizedError(authRequest, error, next, authUser);
              }

              return throwError(() => error);
            }),
          );
        }),
      );
    }

    return next.handle(request);
  }

  private handleUnauthorizedError<T>(
    request: HttpRequest<T>,
    error: HttpErrorResponse,
    next: HttpHandler,
    user: AuthUser | null,
  ) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      if (!user) {
        this.isRefreshing = false;

        return this.logout(error);
      }

      return this.authService.refreshToken(user.refreshToken).pipe(
        concatMap(data => {
          this.isRefreshing = false;

          const authRequest = this.addTokenHeader(request, data.accessToken);

          this.tokenRefreshedSource.next(data.accessToken);

          return next.handle(authRequest);
        }),
        catchError((refreshError: HttpErrorResponse) => {
          this.isRefreshing = false;

          return this.logout(refreshError);
        }),
      );
    }

    return this.tokenRefreshed$.pipe(
      filter(Boolean),
      take(1),
      concatMap(token => next.handle(this.addTokenHeader(request, token))),
    );
  }

  private addTokenHeader<T>(request: HttpRequest<T>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  private logout(error: HttpErrorResponse) {
    this.isRefreshing = false;

    const returnUrl = this.router.url;

    const redirect = this.router.navigateByUrl('/auth/logout', {
      state: { returnUrl },
    });

    return from(redirect).pipe(switchMap(() => throwError(() => error)));
  }
}
