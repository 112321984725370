<div
  [class.supy-dialog--stretch-horizontal]="stretch === 'horizontal' || stretch === 'both'"
  [class.supy-dialog--stretch-vertical]="stretch === 'vertical' || stretch === 'both'"
  class="supy-dialog"
  igxOverlayOutlet
>
  <igx-dialog
    (opened)="onDialogOpened($event)"
    (opening)="onDialogOpening($event)"
    (closed)="onDialogClosed($event)"
    (closing)="onDialogClosing($event)"
  >
    <igx-dialog-title class="supy-dialog__header">
      <ng-content select="supy-dialog-header" />
    </igx-dialog-title>

    <div class="supy-dialog__content">
      <ng-content select="supy-dialog-content" />
    </div>

    <div class="supy-dialog__footer" igxDialogActions>
      <ng-content select="supy-dialog-footer" />
    </div>
  </igx-dialog>
</div>
