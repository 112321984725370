<igx-toast [autoHide]="false" [positionSettings]="positionSettings" class="supy-version-check__toast">
  <div>
    <p i18n="@@newAppVersionFound">New application version found.</p>
    <p i18n="@@pleaseSaveWork">
      Please, save your work and
      <span class="supy-version-check__refresh" (click)="refresh()">refresh the page</span>
    </p>
    <p><small i18n="@@refreshAllTabsNotice">Note: This will refresh all other open tabs.</small></p>
  </div>
</igx-toast>
