import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { INoRowsOverlayParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

import { IconColor, IconSize, IconType } from '../../icon';

export interface CustomNoRowsOverlayContext {
  readonly message?: string;
  readonly icon?: IconType;
  readonly iconSize?: IconSize;
  readonly iconColor?: IconColor;
}

@Component({
  selector: 'supy-no-rows-overlay',
  template: `
    <div class="supy-no-rows-overlay">
      <supy-icon
        [name]="context?.icon ?? 'empty'"
        [size]="context?.iconSize ?? 'huge'"
        [color]="context?.iconColor ?? 'primary'"
      />

      @if (context?.message) {
        <span class="supy-no-rows-overlay__message">{{ context?.message }}</span>
      } @else {
        <span class="supy-no-rows-overlay__message" i18n="@@noData">No Data</span>
      }
    </div>
  `,
  styleUrls: ['./no-rows-overlay.component.scss'],
})
export class NoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  protected context: CustomNoRowsOverlayContext;

  agInit(params: INoRowsOverlayParams<unknown, CustomNoRowsOverlayContext>): void {
    this.context = params.context;
  }

  refresh(params: INoRowsOverlayParams<unknown, CustomNoRowsOverlayContext>): void {
    this.context = params.context;
  }
}
