import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { areArraysEqual, ToggleFeature } from '@supy/common';

import { IconType } from '../../../icon';

export interface TabViewSelectItem<T = string> {
  readonly label?: string;
  readonly value?: T;
  readonly icon?: IconType;
  readonly suffix?: string;
  readonly hidden?: boolean;
  readonly toggleKeys?: ToggleFeature | ToggleFeature[];
  readonly id?: string;
}

@Component({
  selector: 'supy-tabview-select',
  templateUrl: './tabview-select.component.html',
  styleUrls: ['./tabview-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TabViewSelectComponent),
    },
  ],
})
export class TabViewSelectComponent<T> implements ControlValueAccessor {
  private readonly cd = inject(ChangeDetectorRef);
  @Input() items: TabViewSelectItem<T>[];
  selectedIndex: number;

  @Input() set value(item: TabViewSelectItem<T> | T) {
    this.writeValue(item);
  }

  @Output() selectedChanged = new EventEmitter<T>();

  currentValue: TabViewSelectItem<T>;
  disabled: boolean;
  onChange!: (value: T) => void;
  onTouched!: () => void;

  writeValue(tabViewValue: TabViewSelectItem<T>): void {
    if (Array.isArray(tabViewValue)) {
      const isEmpty = !tabViewValue.filter(Boolean).length;

      this.currentValue = isEmpty
        ? this.items[0]
        : this.items.find(s => (s.value ? areArraysEqual(s.value as unknown as [], tabViewValue) : false));
    } else if (tabViewValue && !tabViewValue.value && !tabViewValue.label) {
      const searchValue = tabViewValue as unknown as T;

      this.currentValue = this.items.find(i => i.value === searchValue);
    } else {
      this.currentValue = tabViewValue ?? this.items[0];
    }

    this.selectedIndex = this.items.indexOf(this.currentValue);

    this.cd.detectChanges();
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onClick(item: TabViewSelectItem<T>): void {
    if (this.currentValue?.value === item.value) {
      return;
    }

    this.writeValue(item);
    this.onChange?.(item.value);
    this.selectedChanged.emit(item.value);
  }
}
