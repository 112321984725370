<igx-checkbox
  class="supy-checkbox"
  [checked]="checked"
  [disabled]="disabled"
  [readonly]="readonly"
  [name]="name"
  [indeterminate]="indeterminate"
  (change)="onValueChange($event)"
>
  <ng-content />
</igx-checkbox>
