import { debounceTime, filter, fromEvent, merge, Observable, of, switchMap, takeUntil } from 'rxjs';
import { CdkOverlayOrigin, ConnectionPositionPair, Overlay } from '@angular/cdk/overlay';
import { AfterViewInit, Component, computed, inject, input, output, signal, viewChild } from '@angular/core';

import { Destroyable } from '@supy/common';

import {
  POPOVER_POSITIONS,
  PopoverContainerComponent,
  PopoverPosition,
  popoverPositionSettings,
} from '../popover-container';

export type PopoverTriggerEvent = 'click' | 'hover' | 'focus';
export const DEFAULT_POPOVER_TRIGGER_EVENTS: PopoverTriggerEvent[] = ['hover', 'click'];

@Component({
  selector: 'supy-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent extends Destroyable implements AfterViewInit {
  readonly popoverContainerRef = viewChild(PopoverContainerComponent);

  readonly #overlay = inject(Overlay);

  readonly trigger = input<CdkOverlayOrigin>();
  readonly triggerEvent = input<PopoverTriggerEvent | PopoverTriggerEvent[]>(DEFAULT_POPOVER_TRIGGER_EVENTS);
  readonly maxWidth = input<number>();
  readonly positions = input<PopoverPosition | PopoverPosition[]>();
  readonly hasBackdrop = input<boolean>();
  readonly backdropClass = input<string>();

  readonly closed = output<void>();
  readonly opened = output<void>();

  readonly isOpened = signal(false);

  readonly #popoverDefaultPositions = signal(POPOVER_POSITIONS);

  protected readonly popoverPositions = computed<ConnectionPositionPair[]>(() => {
    if (!this.positions()?.length) {
      return this.#popoverDefaultPositions();
    }

    return [
      ...(Array.isArray(this.positions()) && this.positions()?.length
        ? (this.positions() as PopoverPosition[]).map(p => popoverPositionSettings[p])
        : [popoverPositionSettings[this.positions() as PopoverPosition]]),
    ];
  });

  readonly scrollStrategy = computed(() => this.#overlay.scrollStrategies.reposition());

  ngAfterViewInit(): void {
    const cdkOverlayOriginEl = this.trigger()?.elementRef?.nativeElement as HTMLElement;

    const events = this.getTriggerEvents(cdkOverlayOriginEl);

    events.open$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (!this.isOpened()) {
        this.updateState(true);
      }
    });

    events.close$.pipe(takeUntil(this.destroyed$)).subscribe((event: Event) => {
      const mouseEvent = event as MouseEvent;

      if (this.shouldClosePopover(cdkOverlayOriginEl, mouseEvent)) {
        this.popoverContainerRef()?.close();
      }
    });
  }

  protected getTriggerEvents(cdkOverlayOriginEl: HTMLElement): {
    open$: Observable<unknown>;
    close$: Observable<unknown>;
  } {
    const openEvents: Observable<unknown>[] = [];
    const closeEvents: Observable<unknown>[] = [];

    const triggers = Array.isArray(this.triggerEvent()) ? this.triggerEvent() : [this.triggerEvent()];

    if (triggers.includes('hover')) {
      const hoverOpen$ = fromEvent(cdkOverlayOriginEl, 'mouseenter').pipe(
        debounceTime(100),
        switchMap(() => of(true).pipe(debounceTime(100), takeUntil(fromEvent(cdkOverlayOriginEl, 'mouseleave')))),
        filter(() => this.isStillHovered(cdkOverlayOriginEl) && !this.isOpened()),
      );

      openEvents.push(hoverOpen$);

      closeEvents.push(
        fromEvent(document, 'mousemove').pipe(
          debounceTime(100),
          filter((event: MouseEvent) =>
            this.isMovedOutside(
              cdkOverlayOriginEl,
              this.popoverContainerRef()?.elementRef.nativeElement as HTMLElement,
              event,
            ),
          ),
        ),
      );
    }

    if (triggers.includes('click')) {
      openEvents.push(fromEvent(cdkOverlayOriginEl, 'click'));
    }

    if (triggers.includes('focus')) {
      openEvents.push(fromEvent(cdkOverlayOriginEl, 'focusin'));
      closeEvents.push(fromEvent(cdkOverlayOriginEl, 'focusout'));
    }

    return {
      open$: merge(...openEvents),
      close$: merge(...closeEvents),
    };
  }

  private isStillHovered(element: HTMLElement): boolean {
    return element.matches(':hover');
  }

  protected detach(): void {
    this.updateState(false);
  }

  protected updateState(isOpened: boolean): void {
    this.isOpened.set(isOpened);

    (this.trigger()?.elementRef?.nativeElement as HTMLElement)?.classList?.toggle('popover-trigger-active', isOpened);

    isOpened ? this.opened.emit() : this.closed.emit();
  }

  protected onClosePopover(): void {
    if (!this.isOpened()) {
      return;
    }

    this.updateState(false);
  }

  private shouldClosePopover(cdkOverlayOriginEl: HTMLElement, event: MouseEvent): boolean {
    const popoverEl = this.popoverContainerRef()?.elementRef?.nativeElement as HTMLElement;

    const triggers = Array.isArray(this.triggerEvent()) ? this.triggerEvent() : [this.triggerEvent()];
    const isHoverOrClick = triggers.includes('hover') || triggers.includes('click');

    if (isHoverOrClick) {
      return this.isMovedOutside(cdkOverlayOriginEl, popoverEl, event);
    }

    return true;
  }

  private isMovedOutside(triggerElement: HTMLElement, popoverElement: HTMLElement, event: MouseEvent): boolean {
    return !(triggerElement?.contains(event.target as Node) || popoverElement?.contains(event.target as Node));
  }
}
