<supy-dialog
  position="center"
  width="85vw"
  height="90vh"
  initialState="open"
  (dialogClosed)="onDialogClosed($event)"
  (dialogClosing)="onDialogClosing($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
>
  <supy-dialog-header class="expanded-treemap__dialog-header">
    <div class="expanded-treemap__header">
      <div>
        <h5 class="expanded-treemap__title">{{ customTitle }}</h5>
        <p class="expanded-treemap__subtitle" *ngIf="currency && !subTitle">in {{ currency }}</p>
        <p class="expanded-treemap__subtitle" *ngIf="subTitle">{{ subTitle }} in {{ currency }}</p>
      </div>
      <div class="expanded-treemap__header-icons">
        <ng-container *ngIf="headerTemplate">
          <ng-container *ngTemplateOutlet="headerTemplate" />
        </ng-container>
        <supy-icon name="close-circle" color="error" (click)="closeDialog()" />
      </div>
    </div>
  </supy-dialog-header>
  <supy-dialog-content class="expanded-treemap__content">
    <supy-treemap
      class="expanded-treemap__chart"
      *ngIf="data"
      [data]="data"
      [svgHeight]="svgHeight"
      [svgWidth]="svgWidth"
      [isClickable]="isClickable"
      [colors]="colors"
      [tilingMethod]="tilingMethod"
      [sectionsMap]="sectionsMap"
    >
      <ng-template supyChartTooltip let-datum>
        <ng-container *ngTemplateOutlet="tooltipTemplate; context: { $implicit: datum }" />
      </ng-template>
    </supy-treemap>
  </supy-dialog-content>
</supy-dialog>
