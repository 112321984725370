import { map, Observable, ReplaySubject, startWith } from 'rxjs';
import { EVENTS, IMutableContext, UnleashClient } from 'unleash-proxy-client';
import { Inject, Injectable, OnDestroy } from '@angular/core';

import { UNLEASH_CLIENT } from './unleash.injector';

@Injectable({
  providedIn: 'root',
})
export class UnleashService implements OnDestroy {
  readonly #statusChanges$ = new ReplaySubject<void>(1);
  readonly #hasContext = false;
  #waitForContext = false;

  constructor(@Inject(UNLEASH_CLIENT) private readonly unleash: UnleashClient) {
    this.unleash.on(EVENTS.UPDATE, () => this.#statusChanges$.next());
  }

  get enabledTogglesChanges$(): Observable<string[]> {
    return this.#statusChanges$.pipe(
      map(() => this.enabledToggles),
      startWith(this.enabledToggles),
    );
  }

  get enabledToggles(): string[] {
    return this.unleash
      .getAllToggles()
      .filter(({ enabled }) => Boolean(enabled))
      .map(({ name }) => name);
  }

  async start(waitForContext?: boolean): Promise<void> {
    this.#waitForContext = waitForContext;

    if (waitForContext) {
      // postpone start until we get all context to remove redundant empty API call
      return;
    }

    await this.unleash.start();
  }

  async updateContext(context: IMutableContext): Promise<void> {
    await this.unleash.updateContext({
      ...context,
      properties: {
        ...this.unleash.getContext().properties,
        ...context.properties,
      },
    });
  }

  update(hasContext?: boolean): void {
    if (this.#waitForContext && !this.#hasContext && hasContext) {
      // start if were waiting for full context and it is available now
      void this.unleash.start();
      this.#waitForContext = hasContext;
    }
    this.unleash.emit(EVENTS.UPDATE);
  }

  ngOnDestroy(): void {
    this.unleash.stop();
  }
}
