import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IRangeSliderValue, ISliderValueChangeEventArgs } from '@infragistics/igniteui-angular';

@Component({
  selector: 'supy-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent<T> implements ControlValueAccessor {
  private readonly control = inject(NgControl, { optional: true });
  @Input() readonly initialValue: number;
  @Input() readonly min: number = 1;
  @Input() readonly max: number = 100;
  @Output() readonly valueChange = new EventEmitter<ISliderValueChangeEventArgs>();
  @Output() readonly dragFinished = new EventEmitter<number | IRangeSliderValue>();
  #value: number;

  onChange: (value: T) => void;

  onTouched: () => void;

  touched = false;

  disabled = false;

  constructor() {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  writeValue(value: number): void {
    this.#value = value;
  }

  registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
