import { takeUntil } from 'rxjs';
import { ApplicationRef, inject, Injectable, Signal, Type, ViewContainerRef } from '@angular/core';

import { IDialogComponent } from '../interfaces';

type OmitPrototype<T> = Pick<
  T,
  {
    [K in keyof T]: T[K] extends Signal<unknown> ? K : T[K] extends (...args: unknown[]) => unknown ? never : K;
  }[keyof T]
>;

@Injectable()
export class DialogService {
  private readonly applicationRef = inject(ApplicationRef);

  openDialog<TDialog extends IDialogComponent>(
    component: Type<TDialog>,
    inputs: Partial<OmitPrototype<TDialog>> = {},
    viewContainerRef?: ViewContainerRef,
  ): TDialog {
    const appViewRef = this.applicationRef.components[0].injector.get(ViewContainerRef);
    const viewContainer = viewContainerRef ?? appViewRef;
    const componentRef = viewContainer.createComponent(component);

    const { instance, changeDetectorRef } = componentRef;

    instance.dialogClosed.pipe(takeUntil(instance.destroyed$)).subscribe(() => {
      const index = viewContainer.indexOf(componentRef.hostView);

      viewContainer.get(index).destroy();
    });

    Object.assign(instance, inputs);

    changeDetectorRef.detectChanges();

    instance.openDialog();

    return instance;
  }

  closeDialog<TDialog extends IDialogComponent>(instance: TDialog): void {
    instance.closeDialog();
  }
}
